@import "shared";

.step_card_form {
  .input-cvv {
    .andes-form-control {
      &__embedded {
        width: auto;
      }
    }
  }
}

.input-cvv {
  position: relative;
  width: 40%;

  .andes-form-control {
    &__message {
      white-space: nowrap;
    }

    &__embedded {
      width: 0;

      .icon-card-cvv--virtual {
        margin-left: em(15);
      }
    }
  }

  .card-hint {
    background: url("../assets/images/credit-card/back-generic@1x.png") no-repeat;
    position: absolute;
    right: em(-60);
    top: em(10);
    width: em(46);
    height: em(27, 14); // Override Styles
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.input-cvv--front .card-hint {
  background-image: url("../assets/images/credit-card/back-amex@1x.png");
}

@media (min-device-pixel-ratio: 2), (resolution >= 192dpi), (resolution >= 2dppx) {
  .input-cvv .card-hint {
    background-size: 100% auto;
    background-image: url("../assets/images/credit-card/back-generic@2x.png");
  }

  .input-cvv--front .card-hint {
    background-image: url("../assets/images/credit-card/back-amex@2x.png");
  }
}
