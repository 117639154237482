@import "shared";

.sticky-summary {
  &.collapsible {
    &.closed {
      border-bottom-left-radius: em(8);
      border-bottom-right-radius: em(8);
      box-shadow: 0 em(6) em(16) 0 rgba(0, 0, 0, 0.1);

      .sticky-summary__row-container {
        padding: em(16) em(4) 0;
      }
    }
    &.open {
      .collapsible-title {
        font-size: $cow-font-14;
        font-weight: $cow-font-weight-semibold;
        border-top: unset;
      }
      .collapsible-summary {
        .row {
          .sticky-summary {
            &__text,
            &__price {
              color: $andes-gray-550;
            }
            &__price.combination {
              .text {
                font-weight: $cow-font-weight-semibold;
                color: $cow-translucent-dark-gray;
              }
              .installments {
                font-size: $cow-font-12;
              }
            }
          }
          &:first-child {
            .sticky-summary {
              &__price.combination {
                .text {
                  font-weight: $cow-font-weight-regular;
                }
              }
            }
          }
        }
      }
      .sticky-summary__row-container {
        border-top: 1px solid $cow-gray-070;
      }
      .sticky-summary__title {
        font-size: $cow-font-14;
        font-weight: $cow-font-weight-semibold;
        border-top: 1px solid $cow-gray-070;
      }
    }
    .down,
    .up {
      .icon-wrapper {
        path {
          fill: $andes-accent-color;
        }
      }
    }
  }

  .tyc_consumer_credits {
    line-height: em(12);
    font-size: em(12);
    margin-bottom: em(16);
    text-align: center;
  }
}

.overlay-visible {
  background-color: rgba(0, 0, 0, 0.8);
}

#group_content {
  .c-title {
    color: $cow-translucent-dark-gray;
    margin-top: em(26);
    margin-bottom: em(26);
  }
  .group-split-card-review {
    .select_row {
      .title-h2 {
        color: $cow-translucent-dark-gray;
        font-size: $cow-font-14;
      }
      .text {
        font-size: $cow-font-14;
      }
    }
    .payment-method-info {
      box-shadow: 0 em(6) em(16) 0 rgba(0, 0, 0, 0.1);
    }
  }
}

#shield_footer {
  height: em(32);
}

@media (max-height: em(667)) {
  #group_content {
    height: calc(100vh);
  }
}
