@import "shared";

.layout-main .optimus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  inset: 0;
}

.navbar {
  margin: 0;
}

.pref-expired,
.pref-unavailable {
  .oops__message {
    font-size: em(18);
  }
}

.group-generic#no_account_balance {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: em(16);

  .group-generic-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .c-title {
      max-width: 100%;
    }
  }

  .group_form .andes-button {
    padding: 0 em(8);
    margin-bottom: 0;
  }
}
