@import "shared";

.layout-main {
  padding: 0;

  .status-bar {
    height: em(24);
  }

  .container {
    &-buyer {
      h1,
      .description {
        padding: 0 em(16, 20);
      }
    }

    &-developer {
      width: 100%;
      margin-top: 0;
      height: calc(100vh - em(24));
      display: flex;
      flex-direction: column;

      .message-group {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .icon-wrapper {
          width: auto;
          display: block;
          margin-bottom: em(22);
          .icon-warning-badge {
            position: relative;
          }
        }

        h1 {
          line-height: em(30, 24);
          margin-bottom: em(16, 24);
        }
      }

      .button-group {
        flex-direction: column;
        margin-bottom: em(16);
        .andes-button {
          &--loud {
            margin-right: em(8);
          }
        }
      }
    }
  }
}
