@import "shared";

.andes-form-control.andes-form-control--textfield.ui-input-text.required {
  padding-top: em(14.3);
}

#entity_with_tooltip {
  display: flex;
  position: relative;
}

#entity_type {
  flex-grow: 1;

  #entity_type-trigger {
    margin-bottom: 0;
  }
}

.icon-with-tooltip {
  position: absolute;
  margin: em(0.5) em(7) em(0) em(110);
}

.group-identification {
  margin: 0;

  .group-identification__children {
    padding-top: em(16);

    .andes-form-control.andes-form-control--textfield.input-document.ui-input-text {
      padding-top: em(8);
    }
  }
}
