@import "shared";

.sticky-summary {
  border-bottom-right-radius: em(8);
  border-bottom-left-radius: em(8);
  box-shadow: 0 em(6) em(16) 0 rgba(0, 0, 0, 0.06);
}

#group_content {
  padding-top: 72px;
}

.c-title {
  text-align: left;
  font-size: $font-size-12;
  margin: 32px 0 8px 0;
}

.group-back-url {
  padding: 0;
}

.andes-card.input-amount-card {
  & > .andes-card__content {
    padding: 24px;
  }
}

