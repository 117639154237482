@import "../express/styles.mobile";

div.ui-card {
  padding: em(24);

  .group-identification {
    margin: 0;

    & ~ .andes-form-control--textfield {
      margin: em(30) 0 em(4);

      .andes-form-control__bottom {
        margin-bottom: 0;
      }
    }

    .group-identification__children {
      & ~ .andes-form-control__message {
        margin: em(8) 0 em(8) em(6);
      }
    }
  }
}
