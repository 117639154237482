@import "shared";

.step_opensea_credits_first_use {
  .layout-main {
    padding: 0;
  }
}
.skeleton {
  &-header {
    height: em(64);
    background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
    background-size: 400% 400%;
    margin-bottom: em(34);
    animation: gradient 1500ms ease infinite;
  }

  &-title {
    height: em(21);
    width: em(270);
    background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
    background-size: 400% 400%;
    margin: auto;
    border-radius: em(4);
    animation: gradient 1500ms ease infinite;
  }

  &-secondary {
    height: em(21);
    width: em(130);
    background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
    background-size: 400% 400%;
    margin: auto;
    margin-top: em(13);
    margin-bottom: em(33);
    border-radius: em(4);
    animation: gradient 1500ms ease infinite;

    &.bottom {
      margin-top: em(59);
      margin-bottom: em(29);
    }
  }

  &-item {
    height: em(72);
    width: em(316);
    background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
    background-size: 400% 400%;
    margin: em(12) auto;
    border-radius: em(4);
    animation: gradient 1500ms ease infinite;
  }
}
