@import "default.scss";

.review-saved-card-item {
    padding: $cow-spacing-24;
    
    .andes-list__item-first-column {
      padding: 0;
  }
}
.single_installment-notification-message {
    margin-top: $cow-spacing-16;
}

ul + .andes-card__footer,
ul + .andes-card__footer .andes-card__footer__content {
  padding: 0;
}

.review-express__collapsed-content.has-content  {
  padding: 0 $cow-spacing-24 $cow-spacing-24 !important;
}

#ReviewCardListV2BottomContent > div.installments-selector > div > div > button  {
  margin-top: 3px;
  margin-bottom: 0px;
}

#consumer_credit_row > button > div > div.andes-list__item-asset > .andes-thumbnail-container > .andes-thumbnail--circle {
  border: 0;
}

.review-express {
  &__collapsed-content {
    & > div:nth-child(2) {
      margin-top: $cow-spacing-32;
    } 
  }

  &__bank_interests {
    margin: $cow-spacing-16 0;
  }

  &__consumer-credits {
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      & > span:not(:first-child) {
        line-height: 15px;
        margin-top: $cow-spacing-32;
      }
    }

    &__modal {
      &__content {
        width: 100%;
        height: 100%;

        iframe {
          display: block;
          border: solid 1px $cow-translucent-lighter-gray;
        }
      }
    }
  }
}