@import "shared";

.sub_step_consumer_credits_tyc {
  background: $cow-white;

  .layout-main {
    padding: 0 em(16);

    .button-back-arrow {
      display: contents;
    }

    .c-title {
      font-size: em(22);
    }

    .credits-conditions {
      height: em(568);
    }
  }
}
