@import "shared";

.layout--redirect,
.layout--embed {
  .modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $cow-white;
    z-index: 10000;
    color: $cow-translucent-dark-gray;

    .inner-wrapper {
      margin: 32px;
    }

    .header {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: em(32);
      margin-top: em(48);
    }

    .arrow {
      display: none;
    }

    .cross {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: em(24);

      svg {
        align-items: center;
      }
    }

    .cross svg path {
      fill: black;
    }

    .icon-back {
      align-items: center;
    }

    .title {
      font-size: em(32);
    }

    .content {
      background: $cow-white;
      font-size: em(14);
      color: $cow-translucent-dark-gray;
      overflow-y: scroll;
      height: 70vh;
      padding-bottom: em(70);
    }
  }
}

.modal-content.shield-modal {
  .inner-wrapper {
    background: url("../assets/images/kycshield.svg") no-repeat;
    background-size: 100% auto;
    margin: em(32) 0;

    .header {
      padding: 0 em(32);
    }

    .group-flex {
      flex-direction: column;
      text-align: center;
      padding: em(80) em(32) 0 em(32);
      align-items: center;

      .title-h3 {
        font-weight: $cow-font-weight-semibold;
        font-size: $cow-font-24;
        color: $cow-translucent-dark-gray;
        width: unset;
      }

      .title-h2 {
        font-weight: $cow-font-weight-regular;
        margin-top: em(8);
      }

      .kyc-why-link {
        font-size: $cow-font-14;
        font-weight: $cow-font-weight-semibold;
        color: $cow-lighter-blue;
      }

      .andes-button.andes-button--loud {
        margin-top: em(24);
        margin-bottom: em(8);
      }
    }
  }

  &.without-icon .inner-wrapper {
    background: none;
  }
}

.step_opensea_credits_first_use {
  .layout--redirect .modal-content .modal-wrapper .inner-wrapper .cross {
    display: grid;
  }
}

// This will be removed when the ENDGAME MLC experiment ends.
.step_card_form,
.step_review {
  .shield-endgame {
    .backdrop {
      display: none;
    }

    .modal-wrapper {
      padding: 0;
    }
  }
}

.step_express {
  .modal-content.shield-modal{
    .modal-wrapper{
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .header {
        display: flex;
        position: absolute;
        top: 0;
      }

      #group-flex {
        padding-top: em(180);

        #change_payment_form {
          width: 100%;
        }
      }
    }
  }
}
