@import "shared";

.layout-main {
  padding: 0 em(16);
}

.sandbox-badge {
  font-size: em(12); // scales down the size of the banner so it's less intrusive in small screens
}

.step_expired {
  .layout-main {
    padding-top: 50%;
  }
}

.layout--wallet {
  background: $cow-white;
}

// Promos link
.layout__col-content {
  padding-top: 1px; // Make margins work

  .bank-deals-link {
    position: relative;
    display: inline-block;
    left: 50%;
    float: left; // needed for negative top / bottom margins to work with inline-block
    transform: translateX(-50%);
    padding: 0 em(32, 14);
    margin: em(-21, 14) 0 em(8, 14);
    line-height: em(32, 14);
    text-align: center;

    + * {
      clear: both;
    }
  }
}

.step_review.ios,
.step_review_split_payment.ios {
  .optimus {
    display: block;
  }
}

.group-back-url {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;

  .chevron-left {
    margin: 0 em(6);
  }

  .andes-button {
    margin: 0;
    line-height: em(14);
    font-size: em(14);
    width: unset;
    min-height: 1.25em;
    padding-top: 1px;
  }
}

.button-back-arrow + .brand {
  padding-right: em(25);
}
