.title-h2 {
  text-align: center;
  margin-top: em(24);
  font-size: em(18);
}

#button_back {
  &_chevron {
    display: inline-block;
    vertical-align: middle;
    margin-left: em(-14);

    .icon-wrapper {
      margin-bottom: em(6);

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &_chevron + .c-title {
    display: inline-block;
    margin: em(24) 0 0 0;
    width: 94%;
    font-size: $cow-font-20;
  }
}

#group_form {
  padding: 0 em(20) em(15) em(20);

  .andes-button--loud {
    margin-bottom: em(20);
  }

  .ui-card {
    box-shadow: none;
    margin-bottom: 0;
    padding: 0;
  }
}
