@import "shared";

// Logged In Scenario
:has(.brand):not(:has(.sticky-summary__row-container)) .c-title {
  margin-top: 92px;
}

.page-mask #group_content .c-title {
  font-size: 20px;
}

// Guest Scenario
:has(.brand):not(:has(.sticky-summary__row-container)):not(:has(.cow-navbar-v2)) .c-title {
  margin-top: 40px;
}

.ui-card {
  padding: 24px;

  .input-cvv {
    width: 100%;
  }

  .expiration-date,
  .input-expiration-date {
    margin-bottom: 32px;
  }

  .security-code {
    margin-bottom: 0px;
  }
}

@media (height <=900px) {
  .scroller {
    padding-bottom: em(105);
  }
}
