.collapsible-selector-container.installments-collapsible-selector {
  &.folded {
    .andes-list__item {
      padding: em(24) em(16);

      .andes-list__item-primary {
        .ui-avatar.icon-installments {
          width: em(34);
          height: em(34);

          span {
            font-size: $cow-font-18;
          }
        }

        .installment {
          font-size: em(15);
          line-height: em(20);

          .installment-amount {
            margin-left: em(5);
          }
        }

        .interest-amount {
          font-size: em(13);
        }
      }
    }
  }

  &.unfolded {
    .andes-list__item {
      padding: em(32) em(16) em(32) em(24);

      &.option-with-interest {
        padding: em(24) em(16) em(24) em(24);
        line-height: em(20);
      }

      .andes-list__item-primary {
        .installment {
          font-size: em(16);

          .quantity.single-digit {
            margin-right: em(16);
          }

          .quantity.double-digit {
            margin-right: em(10);
          }
        }

        .interest-amount {
          font-size: em(12);
          margin-left: em(45);
        }
      }
    }
  }

  .andes-list__item {
    &.title {
      padding: em(14) 0 em(14) em(24);
    }

    .andes-list__item-primary {
      & > div {
        display: flex;
        align-items: center;

        .ui-avatar {
          width: em(48);
        }

        .icon-wrapper {
          margin-right: em(20);
          margin-top: 0;
        }

        .interest-amount {
          color: $cow-translucent-medium-gray;
        }

        .rate {
          margin-left: em(5);
        }
      }
    }

    .andes-list__item-tertiary .tertiary {
      display: flex;
      text-align: right;

      .cft {
        display: flex;
        flex-direction: column;
        font-size: em(14);
        margin-right: em(16);

        span:last-of-type {
          color: $cow-translucent-medium-gray;
        }
      }
    }
  }
}

@media (width <= 320px) {
  .collapsible-selector-container.installments-collapsible-selector {
    &.folded {
      .andes-list__item-primary {
        .installment-label {
          .interest-amount {
            width: 70%;
          }
        }
      }
    }
  }
}
