@import "shared";

.ui-card {
  padding: em(16);

  /*
  // BPP
  */
  .group-congrats-shipping:nth-child(4) {
    .group-media-object:nth-child(3) {
      flex-direction: column;

      svg {
        width: 100%;
      }

      .group-congrats-shipping {
        width: 100%;
        text-align: center;
        margin-top: em(20);
      }
    }
  }

  .group-congrats-shipping:nth-child(3) {
    .andes-badge.andes-badge--pill.andes-badge--green.badge:first-child {
      margin: 0;
    }

    .andes-badge.andes-badge--pill.andes-badge--green.badge + .group-media-object:nth-child(2) {
      margin: 0;

      .row-details {
        margin: em(4) 0;
      }
    }

    .group-media-object:nth-child(2) {
      margin: 0 em(-24) em(10);

      .andes-badge {
        margin-top: 0;
        padding-right: 0;
      }
    }

    .group-media-object:nth-child(3) {
      flex-direction: column;

      svg {
        width: 100%;
      }

      .group-congrats-shipping {
        width: 100%;
        text-align: center;
        margin-top: em(20);
      }
    }
  }

  /*
  // END BPP
  */

  #incentive_download_button_id {
    margin-bottom: em(12);

    & ~ .text {
      text-align: center;
      margin-bottom: 0;
    }
  }

  .andes-button#awareness_download_button_id {
    text-indent: unset;
  }
}

.group-split-congrats {
  .ui-card {
    // Summary information to user
    > .row-details {
      margin-left: em(54);
    }

    .disclaimer-row {
      margin: 16px -16px -24px;
    }
  }

  // Total text
  .group-generic:last-child {
    .title-h2:first-child {
      margin-right: em(18);
    }
  }

  // BPP
  .group-media-object#grand_total + .group-congrats-shipping {
    .group-media-object > .group-congrats-shipping {
      .andes-badge--pill.andes-badge--green.andes-badge--small {
        margin-left: 0;
      }
    }
  }

  // End BPP
}

#group_card_combination .ui-card .group-media-object + .group-media-object {
  + .group-flex .title-h2:first-child {
    width: em(56);
  }
}

//TODO Delete when growth coupon incentive ends
.group-congrats-growth-incentive {
  .ui-card {
    #group_generic_growth_incentive {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: em(20);

      .row-instructions {
        .title-h2 {
          font-size: $cow-font-18;
          margin-bottom: em(4, 18);
          color: $cow-color-success;
        }

        p.text {
          font-size: $cow-font-16;
          font-weight: $cow-font-weight-regular;
          line-height: $cow-spacing-20;
          margin-bottom: em(4);
        }
      }

      .icon-wrapper {
        width: auto;
        border: none;
      }
    }
    & > .text {
      font-size: $cow-font-12;
      margin-bottom: em(16, 12);
      font-weight: $cow-font-weight-regular;
      color: $cow-translucent-medium-gray;
    }

    #incentive_download_button_id {
      margin-bottom: em(12);

      & ~ .text {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .cow-button-external-link {
    width: 100%;
  }
}
