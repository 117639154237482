.sticky-summary {
  --cow-light-blue: #{$andes-accent-color};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 $gutter-base;
  line-height: em($navbar-mobile-height);
  background-color: $cow-white;
  transition: background 250ms ease, box-shadow 250ms ease;
  transform: translateZ(0); // Use hardware acceleration for fixed elements for better fps in mobile
  z-index: 200;

  &__separator-row {
    width: 100%;
    border: 0;
    border-top: em(1) solid $cow-light-gray;
  }

  &__transitioning {
    box-shadow: 0 em(10) em(10) 0 $cow-translucent-transparent-gray;
  }

  &__row-container {
    display: flex;

    .sticky-summary__text {
      font-size: em(16);
      font-weight: $cow-font-weight-semibold;
      color: $cow-translucent-dark-gray;
    }

    .sticky-summary__price {
      font-size: em(16);
      font-weight: $cow-font-weight-semibold;
      color: $cow-translucent-dark-gray;
    }
  }

  &__text {
    flex-grow: 1;
    padding-right: em(16, 18);
    color: $cow-translucent-dark-gray;
  }

  &__price {
    flex-shrink: 0;
    color: $cow-translucent-dark-gray;
  }

  &.coupon.collapsible {
    @at-root .optimus .navbar {
      z-index: 250;
    }
  }

  &.coupon {
    .row_discount {
      .sticky-summary__text {
        flex-grow: 0;
        padding-right: 0;
      }

      .sticky-summary__price {
        flex-grow: 2;
      }

      .question-mark {
        flex-grow: 3;
        align-self: center;
      }
    }
  }

  .chevron-button-wrapper {
    display: none;
  }

  &.collapsible {
    border-radius: 0 0 em(12) em(12);
    z-index: 202;

    .collapsible-title {
      display: block;
      font-size: em(14);
      transition: height 0.8s ease, margin-bottom 0.8s ease;
      height: em(18, 14);
      overflow: hidden;
      line-height: em(18, 14);
      color: $cow-translucent-dark-gray;
      border-top: em(1) solid $cow-light-gray;
      font-weight: 600;

      &.hidden {
        height: 0;
        opacity: 0;
      }

      &.open {
        height: em(34, 14);
        padding-top: em(16, 14);
        opacity: 1;
        transition: opacity 1s ease-in;
      }

      &:empty {
        display: none;
      }
    }

    .sticky-summary__row-container {
      line-height: em(20);
      padding-top: em(12);
      padding-bottom: em(12);

      &.extra-info {
        font-size: $cow-font-14;
        padding-bottom: em(1, 14);

        &.animate {
          animation: fade-in 1000ms ease-in;
        }

        &.discount {
          .sticky-summary__text {
            color: $andes-success-green;
          }
        }

        .sticky-summary__text {
          font-weight: normal;
          color: $cow-translucent-medium-gray;

          .extra-label {
            margin-left: em(4, 14);
            color: $cow-translucent-medium-gray;
          }
        }

        .sticky-summary__price {
          font-weight: normal;
          color: $cow-translucent-medium-gray;
          font-size: $cow-font-14;
        }
      }
    }

    .sticky-summary__row-container:nth-of-type(3) {
      padding-top: 0;
      border-top: 0;
    }

    .collapsible-summary {
      border-radius: 0 0 0.25em 0.25em;
      overflow: hidden;
      transition: max-height 0.8s;

      .cow-accordion {
        margin-top: em(12);
      }

      &.open {
        max-height: 50vh;
        margin-bottom: em(16);
      }

      &.hidden {
        max-height: 0;
      }

      > .row,
      .row_discount,
      .row_collapsible {
        margin-top: em(8);
      }

      > .row,
      .row-with-separator,
      .row_discount {
        display: flex;
        line-height: em(18);
        align-items: center;

        > .sticky-summary__price {
          font-size: $cow-font-14;

          &.with-label {
            text-align: right;
          }

          > span {
            float: right;
            clear: both;
          }

          > .extra-label {
            font-size: em(12, 14);
            color: $cow-color-success;
            line-height: em(14, 14);
            margin-bottom: 0;
          }
        }

        > .sticky-summary__text {
          font-size: $cow-font-14;
        }

        .sticky-summary__title {
          border-top: em(1, 14) solid $cow-light-gray;
          width: 100%;
          padding-top: em(16, 14);
          margin-top: em(8, 14);
          font-size: $cow-font-14;
        }
      }

      .row_discount {
        border-top: 0;

        .sticky-summary__text {
          color: $andes-success-green;

          &.with-label {
            .extra-label {
              margin-left: em(4, 14);
              color: $cow-translucent-dark-gray;
            }
          }
        }
      }

      > .row-with-separator {
        border-top: em(1) solid $cow-light-gray;
        line-height: em(39);
      }
    }

    button.chevron-button-wrapper {
      width: 100%;
    }

    .chevron-button-wrapper {
      font-size: em(10);
      color: $cow-dark-gray;
      border: 0;
      background: none;
      border-radius: em(4);
      padding: 0;
      display: block;

      &:hover,
      &:active,
      &:focus {
        color: $cow-dark-gray;
      }

      &:focus {
        outline: none;
      }

      span {
        .icon-wrapper {
          margin: auto;
        }

        &.down svg {
          opacity: 1;
          transform: rotate(90deg);
          display: inline-block;
        }

        &.up svg {
          top: em(-1, 12);
          transform: rotate(270deg);
        }

        svg {
          position: relative;
          top: em(-3, 12);
          display: inline-block;
          display: block;
          margin: auto;

          path {
            fill: $andes-accent-color;
          }
        }
      }
    }

    &.closed {
      box-shadow: 0 em(3) em(6) 0 $cow-translucent-lighter-gray;
    }
  }

  &.open .sticky-summary__row-container {
    padding-top: em(12);
    border-top: em(1) solid $cow-light-gray;
  }

  .collapsible-title {
    display: none;
  }

  #summary-list {
    margin-top: em(12);
    .andes-list {
      &__item {
        padding: 0;
        cursor: unset;
        margin-bottom: em(6);

        &-primary,
        .secondary-text {
          font-size: $cow-font-14;
          font-weight: $cow-font-weight-regular;
          display: flex;
          align-items: center;
          color: $andes-gray-550;
          margin-left: em(16);
        }
        &-primary {
          margin-left: 0;
        }

        &-first-column,
        &-second-column {
          padding: 0;
        }
        &:hover {
          background-color: transparent;
        }
        &:first-child {
          .andes-list__item-first-column {
            .andes-list__item-primary {
              .secondary-text {
                margin-left: 0;
              }
            }
          }
          .secondary-text {
            color: $cow-translucent-dark-gray;
          }
        }
      }
    }
    .icon-with-tooltip {
      .andes-tooltip__trigger {
        max-width: unset;
        padding: 0 em(8);
      }
      .andes-tooltip__text {
        padding: 0;
      }
    }
    & + .row {
      .sticky-summary__text.combination,
      .sticky-summary__price.combination {
        padding: 0;
        margin: 0;
        border-top: unset;
        font-weight: $cow-font-weight-regular;
        color: $cow-translucent-dark-gray;
      }
    }
  }
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 6px 16px 0px $cow-translucent-lighter-gray;
}

.navbar--mp,
.navbar--ml {
  & + .sticky-summary {
    .sticky-summary__row-container {
      border-top: none;
    }
  }
}

.overlay-visible {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba($cow-black, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
}

/*
** Centralized section for positioning UserHeader, Navbar and Summary
** We have 3 cases of summary
** Simple: with height of $navbar-mobile-height
** Coupon(Shipping too): with height of $navbar-mobile-height + 8
** Coupon + visible extra-info: with height of $navbar-mobile-height + 25
*/
.navbar + .sticky-summary,
.cow-navbar-v2 + .sticky-summary {
  top: em($navbar-mobile-height);
}

.navbar + .layout__col-content,
.cow-navbar-v2 + .layout__col-content {
  padding-top: em($navbar-mobile-height);
}

// Summary without UserHeader and Navbar
.sticky-summary {
  & + .overlay-invisible + .layout__col-content,
  & + .overlay-visible + .layout__col-content,
  & + .layout__col-content {
    padding-top: em($navbar-mobile-height);
  }

  &.coupon + .overlay-invisible + .layout__col-content,
  &.coupon + .overlay-visible + .layout__col-content,
  &.coupon + .layout__col-content {
    padding-top: em($navbar-mobile-height + 8);
  }

  &.coupon.extra-info + .overlay-invisible + .layout__col-content,
  &.coupon.extra-info + .overlay-visible + .layout__col-content,
  &.coupon.extra-info + .layout__col-content {
    padding-top: em($navbar-mobile-height + 25);
  }
}

// Summary without Navbar
.cow-navbar-v2 + .sticky-summary,
.user-header + .sticky-summary {
  & + .overlay-invisible + .layout__col-content,
  & + .overlay-visible + .layout__col-content,
  & + .layout__col-content {
    padding-top: 112px;
  }

  &.coupon + .overlay-invisible + .layout__col-content,
  &.coupon + .overlay-visible + .layout__col-content,
  &.coupon + .layout__col-content {
    padding-top: em(2 * $navbar-mobile-height + 8);
  }

  &.coupon.extra-info + .overlay-invisible + .layout__col-content,
  &.coupon.extra-info + .overlay-visible + .layout__col-content,
  &.coupon.extra-info + .layout__col-content {
    padding-top: em(2 * $navbar-mobile-height + 25);
  }
}

// Summary without UserHeader, we add 1px because of separation border with Navbar
.navbar:not(.user-header) + .sticky-summary {
  & + .overlay-invisible + .layout__col-content,
  & + .overlay-visible + .layout__col-content,
  & + .layout__col-content {
    padding-top: em(2 * $navbar-mobile-height + 1);
  }

  &.coupon + .overlay-invisible + .layout__col-content,
  &.coupon + .overlay-visible + .layout__col-content,
  &.coupon + .layout__col-content {
    padding-top: em(2 * $navbar-mobile-height + 9);
  }

  &.coupon.extra-info + .overlay-invisible + .layout__col-content,
  &.coupon.extra-info + .overlay-visible + .layout__col-content,
  &.coupon.extra-info + .layout__col-content {
    padding-top: em(2 * $navbar-mobile-height + 26);
  }

  .sticky-summary__row-container.extra-info + .sticky-summary__row-container {
    border-top: none;
  }
}

// Summary with UserHeader and Navbar, we add 1px because of separation border with Navbar
.user-header + .navbar + .sticky-summary,
.cow-navbar-v2 + .navbar + .sticky-summary {
  top: em(2 * $navbar-mobile-height);

  & + .overlay-invisible + .layout__col-content,
  & + .overlay-visible + .layout__col-content,
  & + .layout__col-content {
    padding-top: em(3 * $navbar-mobile-height + 1);
  }

  &.coupon + .overlay-invisible + .layout__col-content,
  &.coupon + .overlay-visible + .layout__col-content,
  &.coupon + .layout__col-content {
    padding-top: em(3 * $navbar-mobile-height + 9);
  }

  &.coupon.extra-info + .overlay-invisible + .layout__col-content,
  &.coupon.extra-info + .overlay-visible + .layout__col-content,
  &.coupon.extra-info + .layout__col-content {
    padding-top: em(3 * $navbar-mobile-height + 26);
  }

  .sticky-summary__row-container.extra-info + .sticky-summary__row-container {
    border-top: none;
  }
}
