@import "shared";

.open-finance-awaiting-container {
  &__lottie {
    margin: em(40) em(8) 0 em(8);
  }

  &__text {
    margin: em(15) em(8) 0 em(8);

    h1 {
      font-size: em(24);
      max-width: em(200);
    }
  }

  .lottie{
    &__container{
      height: 230px;
    }
  }
}
