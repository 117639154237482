@import "shared";

.restart-flow-container {
  h1 {
    width: em(311);
  }

  h2 {
    width: em(311);
  }

  a {
    width: em(311);
    margin: 0 auto;
  }
}
