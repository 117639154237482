.wallet-summary {
  background-color: $cow-white;

  &__row-container {
    display: flex;
    flex-direction: column;
  }

  .row,
  .discount_row {
    display: flex;
  }

  .row:nth-of-type(2) {
    margin-top: em(-16);
    margin-bottom: em(7);
  }

  .row:first-of-type {
    margin-top: em(16);
    margin-bottom: em(16);

    .wallet-summary__text {
      font-size: em(16);
      color: $cow-translucent-dark-gray;
    }

    .wallet-summary__price {
      font-size: em(18);
      color: $cow-translucent-dark-gray;
    }
  }

  &.open {
    .discount_row {
      margin-bottom: em(3);
    }
  }

  &.open {
    .discount_row {
      margin-bottom: em(3);
    }
  }

  &.closed {
    margin-bottom: em(16);
  }

  &.closed {
    margin-bottom: em(16);
  }

  .discount_row {
    margin-bottom: em(14);
    margin-top: em(-16);

    .left {
      display: flex;
    }

    .wallet-summary__text:last-of-type {
      text-align: right;
      padding-right: 0;
      color: $cow-translucent-dark-gray;
    }

    .wallet-summary__text {
      padding-left: em(10);
      padding-right: 0;
    }
  }

  &__text {
    flex-grow: 1;
    padding-right: em(16, 18);
    font-size: em(13);
    color: $cow-translucent-medium-gray;
  }

  &__price {
    flex-shrink: 0;
    font-size: em(13);
    color: $andes-success-green;
  }

  .collapsible-summary {
    transition: max-height 0.8s ease, margin 0.8s ease;
    padding-right: em(16);
    font-size: em(12);

    &.open {
      opacity: 1;
      color: $cow-dark-gray;
      transition: all 0.5s ease;
      margin-bottom: em(16);
      border-top: em(1) solid $cow-translucent-lighter-gray;
      padding-top: em(6);
      margin-top: em(11);

      .terms-and-conditions {
        height: em(100);
        overflow: scroll;
      }
    }

    &.hidden {
      max-height: 0;
      overflow: hidden;
      margin: 0;
      opacity: 0;
    }
  }

  .collapsible-row {
    font-size: em(12);
    color: $cow-translucent-medium-gray;
  }

  .chevron-button-wrapper {
    color: $cow-dark-gray;
    border: 0;
    background: none;
    border-radius: em(4, 12);
    padding: em(4, 12);
    padding-bottom: 0;
    display: block;
    padding-top: em(16);
    font-size: em(16);
    margin-top: em(-7);

    &.transitioning {
      visibility: hidden;
    }

    &:hover,
    &:active,
    &:focus {
      color: $cow-dark-gray;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.21429em rgb(0 158 227 / 15%);
    }

    span {
      .icon-wrapper {
        margin: auto;
      }

      &.down svg {
        opacity: 1;
        transform: rotate(180deg);
      }

      &.up svg {
        top: em(-1, 12);
      }

      svg {
        position: relative;
        top: em(-3, 12);
        display: inline-block;
        display: block;
        margin: auto;
      }
    }
  }
}
