@import "shared";

div.optimus div.group-row {
  min-height: em(72);
  padding: em(16);
  background: $cow-lighter-gray;
  border-radius: em(6);

  .andes-button {
    text-align: left;
    line-height: 1.29;
    margin-left: 0;
  }

  div.group-media-object {
    max-width: em(40);
    padding-right: 0;
    margin-right: em(16);
  }

  @media (width <= 320px) {
    border-radius: 0;
    margin-right: em(-16);
    margin-left: em(-16);
  }
}
