&_template {
  &_guest,
  &_default,
  &_express,
  &_saved_card,
  &_subscription_saved_card,
  &_credits {
    div.cow-secure-fields.security-code > div {
      div.andes-form-control__bottom {
        min-height: auto;
        margin-top: $cow-spacing-4;
        span.andes-form-control__message {
          line-height: $cow-font-12;
          min-height: auto;
        }
      }
    }

    .andes-dropdown.andes-dropdown--form .andes-dropdown__trigger{
      margin-bottom: $cow-spacing-4;
    }

    .installments-selector > .interest-info {
      .interest-info_text {
        font-size: 13px;
        margin-left: 6px;
        display: block;
      }
    }

    .installments-selector {
      & > .andes-dropdown.andes-dropdown--form > .andes-form-control__message {
        margin-top: $cow-spacing-4;
      }

      & > div > .andes-dropdown__trigger {
        margin-bottom: 0px;
      }
    }

    .andes-form-control.andes-form-control--textfield.andes-form-control--error
      > .andes-form-control__control {
      margin-bottom: 4px;
    }

    .cow-secure-fields.security-code,
    .andes-form-control__control {
      margin-bottom: 0;
    }

    .cow-card-switch > .andes-card--flat {
      box-shadow: 0 6px $cow-spacing-16 0 rgba(0, 0, 0, 0.1) !important;
    }

    .cow-card-switch > .andes-card--flat > .andes-card__content {
      padding: $cow-spacing-16;

      div.andes-thumbnail-container {
        margin-right: $cow-spacing-16;

        div {
          width: 40px;
          height: 40px;
        }
      }
    }

    .andes-card {
      > :first-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .layout-main {
      padding: 0;
    }

    .layout--new-interface {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      position: relative;

      .brand {
        .andes-thumbnail img {
          all: unset;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .cow-navbar .cow-navbar__content .user-info {
        align-items: center;
      }

      .cow-payment_summary_mobile {
        .andes-accordion {
          &-panel-transition__inner {
            overflow: visible !important;
          }

          &-header-container__title {
            .andes-list__item-second-column {
              margin-right: -8px;
            }
          }
        }

        // Styles for subscription when aspirational message appears
        &:has(.aspirational-message):has(.items--container):has(
            .total-value--container
          ) {
          & + .layout__main-content.is-payment-summary-static {
            padding-top: 300px;
          }
        }
      }

      .layout__main-content {
        padding: 0 em(16);

        .top-row {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin-bottom: 24px;
          justify-content: center;

          .andes-typography {
            font-size: $cow-font-20;
            margin-bottom: $cow-spacing-8;
          }

          .discount--trigger {
            margin: 8px 0 0 0;

            .andes-button__content {
              font-size: $cow-font-16;

              svg {
                width: em(20);
                height: em(20);
                max-height: em(20);
                max-width: em(20);
                margin-left: 12px;
              }
            }
          }

          .andes-typography {
            margin: 40px 16px 0px;
            display: flex;
            justify-content: center;
          }

          .coupon-button {
            margin-bottom: 0;

            span {
              color: $andes-accent-color;

              path {
                stroke: none !important;
              }
            }
          }
        }

        // SPEI Message
        #card-option__spei,
        .message__free-trial {
          margin-bottom: $cow-spacing-32;
        }

        .content {
          .content-row:not(.slide-down) + .collapsible {
            .coupon-button-wrapper {
              .andes-button {
                opacity: 0;
              }
            }
          }
        }

        .content-row {
          .guest-subtitle {
            margin-bottom: 16px;
          }

          .card-list {
            .card-option {
              .andes-card {
                &__content {
                  padding: em(16) em(24) !important;
                }
              }
            }

            #account_money-row {
              .card-option__main-row .texts .texts__title span {
                word-break: break-word;
              }
            }

            #clabe-row.with-bottom-row {
              .card-option__main-row {
                padding: 0 0 em(16) 0;
              }
            }

            // E-mail Row
            #email-row.email-card-option {
              .card-option__content {
                padding: em(16) em(24) !important;
              }
            }

            /* == Pix Row == */
            // Override CardOption Paddings
            #pix-row.with-bottom-row .card-option__top-row {
              .card-option__content {
                padding: 1rem 0 0 0 !important;
              }
            }

            #pix-row.card-option--mobile .andes-card__content {
              padding: 0 !important;
            }

            // Bottom content
            #pix-row {
              .card-option__main-row {
                padding: 0;

                .cow-icon-wrapper {
                  margin-left: 1rem;
                }

                .card-option-cta {
                  margin-right: 1rem;
                }
              }

              .card-option__disclaimer {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                background-color: $cow-white !important;
                border-top: 1px solid $cow-medium-gray !important;
                border-radius: 0 0 0.375em 0.375em !important;
                margin-top: 1rem;

                .andes-card__content {
                  display: flex;
                  align-items: center;

                  .icon-info {
                    display: none;
                  }

                  .text {
                    color: $andes-gray-550-solid;
                    font-size: $cow-font-12;
                    padding: 1em 1.35em;
                    line-height: 18px;
                  }
                }
              }
            }

            .card-option-cta {
              margin: 0;
              color: $andes-accent-color;
              font-size: $cow-font-14;
              font-weight: $font-weight-semibold;
              padding-left: em(11.429);
            }

            .cow-icon-wrapper {
              &:has(.icon-account-money-yellow) {
                border: none;
              }

              // Mercado Pago Black icon
              svg.icon-mercado-pago-black,
              svg.icon-account-money-yellow {
                width: 100%;
                height: 100%;
                max-width: fit-content !important;
              }

              svg {
                color: $andes-accent-color;
              }
            }
          }

          .card-credits {
            margin-top: em(16);
            margin-bottom: 24px;
          }

          .message__payment-validation {
            margin-top: em(16);
          }

          .footer-terms {
            margin-top: $cow-spacing-32;
            .cow-footer{
              margin-top: $cow-spacing-40;
            }
          }

          .card-option:last-of-type {
            margin-bottom: 24px;
          }

          .radio-list-item-mobile {
            .andes-thumbnail.icon_account_money_yellow,
            .andes-thumbnail.icon_mercado_credito_yellow {
              border: none;
            }
          }

          .card-taxes-mla {
            padding: 0;
            margin-top: $cow-spacing-20;
            text-align: center;

            &__cost {
              padding: 0;
              font-weight: $cow-font-weight-bold;
            }

            &__total {
              padding: 0;
              margin-top: $cow-spacing-12;
            }
          }

          .bank_interests {
            margin-bottom: $cow-spacing-32;
          }

          .cow-back-link {
            transition: all 150ms ease-in-out;

            &:hover {
              opacity: 0.8;
              color: $andes-blue-500;
            }
          }
        }

        &:has(.rebranding) {
          .rebranding .cow-icon-wrapper {
            svg {
              color: $andes-text-color-primary;
            }
          }
        }

        &:has(.rebranding) {
          .rebranding .cow-icon-wrapper {
            svg {
              color: $andes-text-color-primary;
            }
          }
        }
      }

      // == Margins ==

      // Static
      .layout__main-content.is-payment-summary-static {
        padding-top: 4px;
      }

      // Guest
      .layout__main-content.user-guest {
        padding-top: 58px;
      }

      .layout__main-content.user-guest.cow-brand-content {
        padding-top: 114px;
      }

      // Logged
      &:has(.not-navbar) {
        .layout__main-content.user-logged.cow-brand-content {
          padding-top: 128px;
        }

        .layout__main-content.user-logged {
          padding-top: 58px;
        }
      }

      .layout__main-content.user-logged {
        padding-top: 147px;
      }

      .layout__main-content.user-logged.cow-brand-content {
        padding-top: 205px;
      }

      // ============

      .cow-legals {
        margin-top: em(16);
        margin-bottom: em(24);
        padding-top: em(12);
        justify-content: center;
      }

      // === CAPTCHA ===
      .cow-captcha-terms {
        margin-top: em(32, 16);
        margin-bottom: em(44);
        justify-content: center;

        a {
          color: $andes-blue-500;
          transition: all 150ms ease-in-out;
          text-decoration: none;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .cow-captcha__container {
        margin-top: em(16, 16);
        text-align: center;

        &--guest {
          .text {
            margin: em(12, 14) auto;
            margin-bottom: em(-12, 14);
            font-size: em(14);
            color: $andes-error-red;
          }
        }

        &--logged {
          .text {
            margin: em(12, 14) auto;
            font-size: em(14);
            color: $andes-error-red;
          }
        }

        #g-recaptcha {
          display: flex;
          justify-content: center;
          transform: scale(1.09, 1);

          iframe {
            display: unset;
          }
        }
      }

      .footer-terms--backlink-container {
        display: flex;
        justify-content: center;
        margin: em(24) 0 em(40) 0;
      }

      // TODO: MOMENTOUSLY SOLUTION FOR USE THE ANDES MoneyAmount COMPONENT WITH THE CURRENT DATA STRUCTURE FROM FLOWS
      .andes-money-amount__currency-symbol {
        display: none;
      }

      // == Coupon Collapsible Animation ==
      .content {
        margin-bottom: em(448);

        .content-row--animated {
          position: absolute;
          z-index: 3;
          width: 100%;
          background-color: $cow-gray-070; // Background color of the page

          animation-duration: 0.5s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;

          // TODO: Find a better way
          min-height: em(250);
        }
      }

      @keyframes slideUp {
        0% {
          transform: translateY(400px);
          opacity: 0;
          display: none;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
          display: block;
        }
      }

      @keyframes slideDown {
        0% {
          transform: translateY(0);
          opacity: 1;
          display: block;
        }

        100% {
          transform: translateY(400px);
          opacity: 0;
          display: none;
        }
      }

      .slide-down {
        animation-name: slideDown;
      }

      .slide-up {
        animation-name: slideUp;
      }
    }

    // Show Pay Button above the loader
    .loader-wrapper {
      z-index: 100 !important;
    }

    // === DISCOUNT MODAL ===
    .discount-modal {
      .generic-modal__content {
        color: #999;

        // TODO: REMOVE THIS AND FIX THE text-align defined in the generic-modal of the COW Core Components lib
        text-align: unset !important;
      }
    }

    // === PAYMENT SUMMARY ===
    .cow-sticky__pay--default {
      .cow-sticky__pay__button {
        &:hover {
          background-color: $andes-blue-600 !important;
        }
      }
    }

    // Summary specific styles without brand
    :not(:has(.brand--mobile)) {
      .cow-payment_summary_mobile {
        // Styles Navbar + Summary with 2 rows
        &--static {
          &:has(.items--container):has(.total-value--container) {
            & + .layout__main-content.is-payment-summary-static {
              padding-top: 158px;
            }
          }
        }
      }
    }

    // Styles for special case when the discount has decimals for separate cents
    .andes-money-amount__cents {
      margin-top: 0 !important;
    }

    .cow-payment_summary_mobile__row {
      &--coupon_discount {
        &:has(.andes-money-amount__cents) {
          .row-item__icon-container {
            margin: 0;
            align-items: center;
            justify-content: left;
          }
        }
      }
    }

    #installments-quantity-row {
      .cow-icon-wrapper {
        color: $andes-accent-color;
        border: 1px solid $cow-gray-070;
      }
    }

    .cow-icon-wrapper,
    .payment-card-icon-wrapper {
      border: 1px solid $cow-gray-070;
    }

    .cow-icon-wrapper.icon-mercadopago-full {
      border: none;
    }
  }
}
