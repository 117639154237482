@import "shared";

.installments-list {
  &__total-amount p {
    padding-right: 20px;
  }
}

@media (width <=410px) {
  .installments-list {
    &__total-amount p {
      max-width: em(124, 14);
      float: right;
    }
  }
}