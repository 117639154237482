@import "shared";

.group-row .group-media-object {
  width: 100%;

  .row-details {
    flex-direction: column;
  }

  .group-generic {
    .c-title {
      font-size: em(16);
    }
  }
}

ul.select_row {
  .options-list__item {
    height: auto;
    padding: em(12) 0 em(12);
  }
}
