@import "shared";

.group-summary {
  padding: $gutter-24;
  padding-top: $gutter-32;
  margin: 0 ($gutter-base * -1) $gutter-24;
  background: $cow-white;

  &__container {
    position: relative;
    width: 100%;
  }

  .title-h2 {
    margin-bottom: em(16);
  }

  hr {
    margin: em(8) 0;
  }

  @media (width >= 321px) {
    & {
      margin-left: 0;
      margin-right: 0;
      border-radius: em(4);
    }
  }
}

.step_review {
  .layout__col-content {
    .c-title {
      text-align: center;
    }
  }
}
