@import "shared";

.redirect-view {
  .rebranding__title {
    text-align: center;
  }
}

.form_address_info {
  .form_address_double_field {
    flex-direction: column;
    max-width: 100%;

    .form_address_field {
      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .form_address_info_modal_button {
    min-width: 130px;
  }
}
