@import "shared";

.row-collapsible {

  &__title {
    margin-top: em(16);
  }

  .icon-with-tooltip {
    margin-left: auto;
  }
}
