@import "shared";

.step_open_finance_bank_selection {
  height: 100%;
  background: $andes-white;

  .layout__col-content {
    & .c-title {
      font-size: em(15);
      font-weight: bold;
      margin-top: em(20);
      margin-bottom: em(5);
      text-align: left;
    }
  }

  .andes-list__item {
    padding: 0 em(5);

    & + .andes-list__item {
      border-top: none;
    }

    &::before {
      border-bottom: em(1) solid $andes-gray-550;
      border-right: em(1) solid $andes-gray-550;
    }
  }

  .optimus .ui-card {
    box-shadow: none;
  }
}
