@import "shared";

.scroller {
  .content {
    .group-row:last-of-type {
      margin-bottom: em(30);
    }

    .title-h3 {
      font-size: $cow-font-14;
      line-height: em(18, 14);
      color: $cow-translucent-medium-gray;
      width: em(295, 14);
      margin: 0 auto;
    }

    .title-h3:not(:last-child) {
      margin-bottom: em(24, 14);
    }
  }
}

.optimus {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.layout-terms {
  margin-bottom: em(104);
  line-height: em(14);
}

#group_list_bank_id .ui-card {
  padding: 0 $cow-spacing-16;
  margin-bottom: 22px;
}

.group-row .group_form {
  background: none;
  padding: 0;
  margin: 0;

  button {
    width: auto;
    margin: 0 0 0 auto;
    padding: 0;
  }
}

.group_form .andes-button--loud {
  margin-bottom: 0;
}

.optimus > .title-h2 {
  margin: em(16) 0 0 0;
  text-align: center;
}

.optimus > .captcha-terms {
  &.guest {
    margin-top: 0;
  }
}

.ui-card {
  padding: 0;

  form {
    display: flex;
    align-items: center;
    padding: em(16) em(8) em(24);
  }
}

.layout__col-content {
  .title-h2 {
    margin: em(32) 0 em(12) 0;
  }

  .ui-card-wrapper {
    .ui-card {
      margin-bottom: 0;

      .form {
        padding-left: 0;
        padding-right: 0;
      }

      .icon-wrapper {
        margin-right: em(18);
      }
    }
  }

  & + p.text {
    margin: em(32) em(8) em(4) em(8);
    padding: 0 em(36);
    font-size: em(12);
  }

  .toggle-button-wrapper .andes-button {
    font-size: em(16);
    line-height: 1.8;
  }

  .c-title {
    margin-top: em(32, 18);
    margin-bottom: em(32, 18);
    font-size: 20px;

    span {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .group-flex:first-child {
    flex-direction: row;
    margin: em(32) 0;
    justify-content: center;
    vertical-align: middle;

    .c-title {
      margin: 0;
    }

    .c-title + .icon-with-tooltip {
      margin-left: em(8);
      flex-grow: 0;
    }
  }

  .group-flex:first-child + .toggle-button-wrapper {
    margin-top: em(-24);
  }
}

h1.c-title + div.toggle-button-wrapper {
  margin-top: em(-30);
  margin-bottom: em(28);
}

.group-split-card-review {
  & ~ .group-row {
    margin-bottom: em(50);
  }

  & ~ .title-h2,
  .title-h2 {
    text-align: center;
  }
}

.sticky-summary.collapsible {
  & ~ .c-title {
    margin-top: em(50);
  }
}

.group-split-card-review + .group-split-card-review {
  margin: em(24) 0;
}

&_split_payment {
  .layout__col-content {
    padding-top: em(100);
  }
}

.group-captcha {
  text-align: center;

  &.guest {
    .text {
      margin: em(12, 14) auto;
      margin-bottom: em(-12, 14);
      font-size: em(14);
      color: $andes-error-red;
    }
  }

  &.logged {
    .text {
      margin: em(12, 14) auto;
      font-size: em(14);
      color: $andes-error-red;
    }

    .sticky-container {
      &.bottom {
        padding-bottom: em(48);
      }

      .andes-button {
        margin-top: 0;
      }
    }

    .captcha-terms {
      margin-top: 0;
    }
  }

  #g-recaptcha {
    transform: scale(1.09, 1);
  }
}

&.site-mco {
  .captcha-terms {
    &.logged {
      margin-top: 0;
    }
  }
}

.sticky-container-placeholder {
  &.with-back-url {
    height: em(128); // to compensate pay button and back url height
  }
}

.group-total-summary + .c-title {
  display: inline-block;
  width: 100%;
}

.group-total-summary + .layout__col-content .c-title {
  margin: em(24) 0 em(32) 0;
}

.group-total-summary + .layout__col-content .group-row {
  padding-left: em(24);
}

.group-total-summary + .c-title + .layout__col-content .ui-card-wrapper {
  .ui-card {
    padding: em(24);
  }

  .row_cvv {
    display: flex;
    flex-direction: row-reverse;

    .icon-wrapper {
      width: em(48);
      margin-right: em(16);
    }
  }

  .c-title {
    & ~ .group-row {
      padding-left: em(24);
    }
  }

  .group_row_selectable {
    border-radius: 0 0 em(4) em(4);

    .group-row {
      padding-left: em(24);
    }
  }

  .andes-form-control__message {
    min-height: auto;
  }

  .card-hint {
    display: none;
  }
}

.modal-content.shield-modal .inner-wrapper .group-flex .title-h3 {
  margin-bottom: em(8, 24);
}

#shield_footer.group-generic {
  padding-top: em(24);
  line-height: 1;
}

.sticky-container {
  #shield_footer.group-generic {
    padding-top: em(8);
    line-height: 1;
  }
}

h1 + div.scroller {
  margin-top: em(12);
}

.group-generic-block + .group-row {
  .group-media-object {
    padding: 0;
    max-width: unset;
    margin-right: 0;

    .group_form .andes-button {
      margin: 0;
    }
  }
}

.layout__col-content .ui-card-wrapper .ui-card .form {
  label {
    padding-left: 0;
    padding-right: 0;
  }
}

.layout--redirect,
.layout--embed {
  .modal-content .header {
    margin: 0 0 em(16);
    justify-content: space-between;
  }
}

#group_switch_alternative_payment_method .ui-card {
  padding: 8px 32px 8px 16px;
}

#group_pay {
  .andes-form-control__bottom {
    padding: 0 em(24) 0 em(24);
  }

  ul li {
    margin: em(12) 0;
  }

  #financial_institutions {
    margin-top: em(6);
    padding: em(16) em(24) 0 em(24);
  }
}

#installments_group_generic {
  .andes-dropdown__trigger {
    justify-content: flex-start;

    span {
      max-width: em(220);
    }
  }
}

form#group_installment .ui-card-wrapper {
  .group-generic {
    display: flex;
    flex-wrap: wrap;

    .icon-wrapper {
      margin-right: em(20);
      margin-left: em(-10);
    }

    .select-additional-info {
      margin-top: em(-6);
    }
  }

  #installments_group_generic {
    .andes-dropdown__trigger {
      justify-content: flex-start;

      span {
        max-width: em(220);
      }
    }
  }

  .ui-card {
    padding: em(20) em(32);
    overflow: visible;
    flex-wrap: nowrap;
    z-index: 2;

    .select-installments {
      .andes-dropdown__trigger {
        span {
          max-width: em(200);
        }
      }
    }

    .andes-message--quiet {
      margin-top: em(38);
    }
  }
}

ul.select_row {
  .options-list__item {
    height: auto;
  }
}

ul.select_row,
ul.select_extra_info_row {
  .with-bottom-row {
    padding: 0;

    .options-list__bottomRow {
      padding: em(8) em(24) em(8) em(20);

      .icon-wrapper.icon-info {
        margin-right: em(4);
      }
    }
  }
}

@media (width <= 425px) {
  .andes-list__item {
    flex-wrap: wrap;

    .andes-list__item-first-column {
      padding: 0;
      flex-grow: 4;
      flex-basis: 61%;
    }

    .option-additional-info {
      margin-left: 0;
      line-height: 0;
      padding: 0;
      flex-basis: 40%;
    }
  }
}

@media (width <= 391px) {
  #installments_group_generic {
    .icon-wrapper {
      max-width: 10%;
    }

    .select-installments {
      max-width: 86%;
    }
  }
}

#email_row {
  .ui-card {
    padding-left: em(16);
    padding-right: em(16);
  }
}
#notice-processed-mp.text {
  margin: 0 0 $cow-spacing-20 0;
}

#use_consumer_credits button {
  font-size: em(14);
  margin-bottom: 0;
}
