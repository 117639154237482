@import "shared";

@at-root .step_terms_and_conditions {
  background-color: $cow-white !important;
  height: 100%;
}

.layout {
  .layout-main {
    padding: 0 em(16);

    .button-back-arrow, .c-title {
      display: inline-block;
      vertical-align: middle;
    }

    .c-title {
      font-size: em(22);
    }

    .credits-conditions {
      padding: 0;
    }
  }
}
