@import "shared";

#group_content {
    padding-top: em(56);

    .ui-card-wrapper {
        .ui-card {
            width: 100%;

            .group_form {
                width: 100%;

                .security-code,
                .input-cvv {
                    width: 100%;
                }

                .andes-form-control {
                    .andes-form-control__embedded {
                        display: none;
                    }
                }

                .andes-button--loud {
                    width: 100%;
                    margin: em(4) 0 0 0;
                }
            }
        }
    }
}
