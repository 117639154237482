@import "shared";

.layout__col-sidebar {
  .cow-payment_summary_mobile {
    position: static;
  }
}

.content-split-amount {
  & > .andes-card {
    padding: 24px
  }
}
