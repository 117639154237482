@import "shared";

.congrats {
  .ui-card {
    margin-bottom: 0;
  }

  label.input-cvv {
    position: relative;

    .icon-card-cvv--virtual {
      position: absolute;
      margin-left: em(15);
    }
  }

  .cow-secure-fields {
    width: em(200);
  }

  .cow-secure-fields + .andes-button--loud {
    margin-top: em(24);
  }

  .group-back-url {
    display: flex;
    justify-content: center;
    padding: 24px 0;
    width: auto;

    .chevron-left {
      margin: 0 em(6);
    }

    .andes-button {
      margin: 0;
      line-height: em(14);
      width: 100%;
      min-height: 1.25em;
    }
  }
}
