@import "shared";

.card-number-with-issuer__wrapper {
  .card-number-with-issuer__group {
    display: flex;
    flex-direction: row-reverse;

    & > :nth-child(n) {
      flex: 1;
    }

    .icon-wrapper {
      padding-bottom: em(16);
      flex: none;
      margin-right: em(24);
    }

    .andes-dropdown {
      margin-right: 0;
    }
  }
}
