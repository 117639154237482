@import "shared";

.expiration-date__inner_wrapper {
  .pick-month {
    margin-right: em(8);
  }

  .pick-year {
    display: flex;
    align-items: flex-end;
    margin-left: em(8);

    .andes-dropdown__field {
      width: 100%;
    }
  }
}
