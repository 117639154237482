@import "shared";

.optimus {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .sticky-summary+.overlay-invisible+.layout__col-content {
    padding-bottom: $floating-pay-section-height;
  }
}

#group_content {
  .c-title {
    &:not(#title_combination) {
      font-size: $cow-font-20;
      margin-top: 40px;
    }
  }

  #title_combination {
    font-size: $cow-font-12;
    margin-bottom: 8px;
    text-align: left;
    margin-top: 40px;
  }

  .combination_row {
    margin-left: 0;
    margin-right: 0;

    li.with-switch {
      padding: 2px 6px 2px 24px;

      label.with-switch {
        height: auto;
        padding: 0;

        .row-details {
          padding: 14px 0;
          margin-right: 12px;
          line-height: 18px;
        }

        .andes-switch {
          padding: 10px;
        }
      }

      height: auto;
    }
  }

  #title_2_combination {
    font-size: $cow-font-18;
  }

  .sticky-container-placeholder {
    &.with-description {
      margin-top: 0;
      padding: 0;
    }

    .sticky-container {
      #submit {
        margin-bottom: em(8);
      }

      .andes-button {
        margin: 0;
      }

      #shield_footer {
        margin-top: 12px;
        height: 16px;
      }
    }
  }
}

#group_actions {
  margin-top: 0;
}

.group-back-url {
  padding: 0;
}

.options-list.ui-card.combination_row + .layout__col-content {
  #title_combination {
    margin-top: 0;
  }
}
