@import "shared";

.rebranding {
  &__title {
    text-align: center;
  }
}

// Styles for summary when there is not cow-navbar-v2
.layout-main {
  &:not(:has(.cow-navbar-v2)) {
    .navbar {
      top: 0;

      .brand:after {
        background-color: $cow-translucent-lighter-gray;
      }
    }

    .group-total-summary {
      margin-top: 57px;
    }
  }

  .optimus {
    padding-bottom: 16px;
  }
}
