@import "shared";

.group-identification__children {
  flex-direction: column;

  .andes-dropdown.andes-dropdown--form-native {
    margin-bottom: em(8);
  }

  #cow-select {
    padding-bottom: 32px;

    .andes-dropdown__trigger {
      margin-bottom: 0;
    }
  }
}

.group-identification {
  .andes-form-control__message {
    margin-top: 0;
  }
}
