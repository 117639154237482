@import "shared";

//andes-overrides
.andes-coach-marks__overlay {
  background-color: $cow-overlay-background-color-dark;
}

.payment-options-mobile-screen__content-title{
  text-align: center;
}

.group-back-url {
  padding-top: em(24);
}

.modal-content.group-modal {
  .header {
    margin-bottom: em(8);
  }

  .group-flex {
    margin-top: em(72);

    h2.title-h3 {
      margin-bottom: em(16);
    }

    .andes-button--loud {
      margin-top: em(56);
    }
  }
}

h2.title-h2 {
  font-size: em(12);
  font-weight: 600;
  line-height: 1.5;
  color: $cow-translucent-medium-gray;
}

h2.title-h2 + ul.options-list {
  margin-top: em(12);
}

h1.c-title + div.toggle-button-wrapper {
  margin-top: em(-38);
  margin-bottom: em(8);
}

.options-list {
  &__item {
    &:not(.disabled, .with-action-text, .with-switch) {
      .options-list__label::before {
        right: em(20);
      }
    }
  }
}

#title-guest {
  color: black;
  font-size: em(16);
  font-weight: $cow-font-weight-semibold;
}

#options-title-guest {
  .title-h2 {
    color: black;
    margin-bottom: em(0);
    font-size: em(16);
    font-weight: $cow-font-weight-semibold;
  }
}

#unprotected-purchase {

  p {
    margin-top: em(0.75);
    font-size: $cow-font-12;
    font-weight: $cow-font-weight-semibold;
    line-height: $line-height-base;
    color: rgba(0, 0, 0, 0.55);
  }

  svg {
    width: em(12);
    height: em(13);
    margin-left: em(4);
    margin-top: em(2);
  }

  path {
    fill: rgba(0, 0, 0, 0.55);
  }

  line {
    stroke: rgba(0, 0, 0, 0.55);
  }

}

.sticky-container {
  &.bottom {
    position: absolute;
    padding-left: 0;
    padding-right: 0;
  }

  #shield_footer.group-generic {
    padding-top: em(8);
    line-height: 1;
  }
}

.user-header ~ .layout__col-content {
  #shield_footer.group-generic {
    padding-top: 0;
    line-height: 1;
  }
}

#shield_footer.group-generic {
  padding-top: em(24);
  line-height: 1;
}

.group-generic-block + .group-row {
  .group-media-object {
    padding: 0;
    max-width: unset;
    margin-right: 0;

    .group_form .andes-button {
      text-align: left;
    }
  }
}
