@import "shared";

// Logged In Scenario
:has(.brand):not(:has(.sticky-summary__row-container)) .c-title {
  margin-top: 92px;
}

// Guest Scenario
:has(.brand):not(:has(.sticky-summary__row-container)):not(:has(.cow-navbar-v2)) .c-title {
  margin-top: 40px;
}

.ui-card {
  overflow: unset;
  padding: 24px;

  #cow-select {
    padding-bottom: 32px;
  }

  .andes-form-control--error {
    .andes-form-control__message {
      margin-top: em(8);
    }
  }
}