@import "shared";

.captcha-terms {
  margin-bottom: em(44);
  margin-top: em(-12);

  &.logged {
    margin-top: em(18, 14);
  }
}
