@import "shared";

div.group-generic#login_section {
  .options-list.ui-card.select_login_payment_options {
    .options-list__item.with-bottom-row > .options-list__bottomRow {
      background-color: unset;
      font-size: unset;
      padding-left: unset;
      display: block;
    }
  }
}
