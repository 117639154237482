@import "shared";

.group-menu {
  &__popup {
    top: em(32);
  }

  &__button {
    margin-top: unset;
  }
}

#group_user_header_ml {
  .group-menu {
    &__button {
      margin-top: $cow-spacing-4;
    }
  }
}

