@import "shared";

.modal-content {
  .inner-wrapper {
    margin: 0;

    .header {
      flex-direction: row;

      .cross {
        align-self: flex-end;
        margin-bottom: em(10);
      }
    }

    .zipcode-result {
      .buttons-container {
        flex-direction: column;
        margin: 0 em(32);
      }

      .andes-list {
        max-height: calc(100vh - 200px);
        overflow: scroll;
        margin-bottom: em(24);

        .andes-list__item-secondary {
          margin-top: em(2);
        }

        .andes-list__item-primary {
          font-size: em(16);
        }
      }

      .finder-result--secondary span {
        display: block;

        &:first-of-type {
          font-size: em(14);
          color: $cow-translucent-dark-gray;
        }

        &:last-of-type {
          font-size: em(14);
        }
      }
    }
  }
}
