@import "shared";

#group_form_scroller > .ui-card {
  display: flex;
  flex-flow: column nowrap;
  gap: em(12);

  p.text {
    margin: 0;
  }
}

.andes-form-control--multiline.andes-form-control--textbox {
  .andes-form-control__label {
    top: 0;
  }
}

p.text {
  color: $cow-translucent-dark-gray;

  &:first-of-type {
    color: $cow-translucent-medium-gray;
  }

  &:last-of-type {
    margin-top: em(24);
  }
}

.ui-card {
  padding: em(24);
}
