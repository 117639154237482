@import "shared";

.form_personal_info {
  .form_personal_double_field {
    flex-direction: column;
    max-width: 100%;

    .form_personal_field {
      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
