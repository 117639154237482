@import "shared";

&.redirect-view {
  max-width: 100%;
  overflow-x: hidden;
}

.title-h2 {
  text-align: center;
  margin-top: em(24);
  font-size: em(18);
}

.card-form-group {
  display: flex;
  flex-wrap: wrap;

  > :nth-child(n) {
    flex: 1;
  }

  > .icon-wrapper {
    flex: none;
    margin-right: em(16);

    width: em(48);
    height: em(48);
    margin-left: em(-6); // center with icons
    margin-top: em(2);
    margin-bottom: auto;
  }

  > .icon-card-cvv {
    width: em(46);
    margin-right: em(0);
  }

  > .andes-dropdown {
    margin-right: 0;

    .andes-form-control__field {
      line-height: normal;
    }
  }

  .andes-form-control__embedded {
    visibility: hidden;
  }

  .icon-installments {
    span {
      font-size: em(20);
    }
  }

  .select-additional-info {
    font-weight: 300;
    margin-left: em(0);
    margin-top: em(-21);
    margin-bottom: em(21);
    color: $cow-translucent-medium-gray;
  }
}

.ui-card {
  overflow: visible;
  padding-bottom: em(10);
  padding-top: em(20);
}

.layout__col-content {
  padding-top: em(140);

  // forms buttons
  > .group-generic {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: em(16);
  }
}

.card-number-with-issuer__wrapper {
  .card-number-with-issuer__group {
    flex-direction: row;

    .icon-wrapper {
      margin-right: 0;
      margin-left: em(5);
    }
  }
}
