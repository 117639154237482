.group_form {
  .andes-button--loud + a {
    margin-top: em(20);
    display: block;
    text-align: center;
  }
}

.congrats {
  .ui-card {
    margin-bottom: 0;
  }

  .group_form {
    > button:nth-child(2) {
      margin-top: em(8);
    }
  }

  .group-back-url {
    display: flex;
    justify-content: center;
    padding: 24px 0;

    .chevron-left {
      margin: 0 em(6);
    }

    .andes-button {
      margin: 0;
      line-height: em(14);
      width: 100%;
      min-height: 1.25em;
    }
  }
}
