@import "shared";

.icon-with-tooltip {
  flex-grow: 20;
  margin-left: em(-8);

  .andes-tooltip__trigger {
    max-width: em(16);
    margin: 0;

    .icon-wrapper {
      margin-top: 0;
    }
  }
}

.row-summary__text + .icon-with-tooltip {
  margin-left: em(8);
}

.site-mco .row-details .icon_with_tooltip .icon-with-tooltip [data-tippy-root] {
  position: fixed !important;
  inset: auto em(30) auto auto !important;
  width: auto !important;
  transform: translate3d(0, 0, 0) !important;

  .andes-tooltip .andes-tooltip-data__arrow::before {
    right: em(12);
  }
}