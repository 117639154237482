@import "shared";

div.optimus div.group-total-summary {
  z-index: 255;
  position: relative;
  margin-left: em(-16);
  width: calc(100% + 2em);

  .group-total-summary__container {
    width: 100%;
  }

  .andes-button__content {
    white-space: nowrap;
  }

  .row-summary--discount + hr + div.row-summary {
    margin-bottom: 0;
  }

  .group-summary__container > .group-generic:first-child,
  h1.c-title + .group-generic {
    margin-bottom: em(16);
    padding: em(16);
    background-color: $cow-translucent-lightest-gray;
    border-radius: em(6);
    line-height: em(18);

    .text {
      color: $cow-translucent-dark-gray;
    }

    .c-title {
      margin: 0 0 em(4) 0;
      font-size: em(16);
      text-align: left;
      line-height: em(20);
    }
  }

  /* Summary */
  .group-summary {
    position: relative;
    padding: em(16) em(16) em(16);
    border-radius: 0;
  }

  .group-summary hr {
    margin: em(10) 0;
  }

  .group-row {
    margin-bottom: em(8);
  }
}

div.optimus .user-header.navbar + .navbar {
  position: relative;
}

div.optimus .user-header.navbar + .group-total-summary,
.cow-navbar-v2 + .group-total-summary
{
  margin-top: em($navbar-mobile-height);
}

.navbar + .group-total-summary {
  margin-top: em($navbar-mobile-height * 2);
}

div.optimus .navbar.navbar--ml + div.group-total-summary,
div.optimus .navbar.navbar--mp + div.group-total-summary {
  z-index: 5;
  margin-top: em($navbar-mobile-height);
}

.optimus > .navbar:first-of-type + .group-total-summary {
  margin-top: #{$navbar-mobile-height}#{"px"};
}