@import "./shared";

:not(.legacy-mp-theme) {
  .toggle-button-wrapper {
    justify-content: center;
    align-items: center;

    .toggle-button {
      width: em(170);
      padding: 0;
      margin: 0;
    }
  }

  .coupon-button {
    margin-top: 8px;
  }
}
