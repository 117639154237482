&_template {
  &_default {
    .layout-main {
      padding: 0;
    }

    .layout--new-interface {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      position: relative;

      .brand {
        .andes-thumbnail img {
          all: unset;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .cow-payment_summary_mobile {
        // TODO: TECH-DEBT: MOMENTOUSLY SOLUTION FOR USE THE ANDES MoneyAmount COMPONENT WITH THE CURRENT DATA STRUCTURE FROM FLOWS
        .andes-money-amount__currency-symbol {
          display: none;
        }
      }

      .cow-navbar .cow-navbar__content .user-info {
        align-items: center;
      }

      .layout__main-content {
        padding: 0 16px;

        .top-row {
          display: flex;
          flex-flow: column wrap;
          padding: 40px 0 32px;

          .title {
            align-self: center;
          }
        }
      }
    }

    // == Margins ==

    // Static
    .layout__main-content.is-payment-summary-static {
      padding-top: 4px;
    }

    // Guest
    .layout__main-content.user-guest {
      padding-top: 58px;
    }

    .layout__main-content.user-guest.cow-brand-content {
      padding-top: 114px;
    }

    // Logged
    .layout__main-content.user-logged {
      padding-top: 146px;
    }

    .layout__main-content.user-logged.is-payment-summary-static {
      padding-top: 114px;
    }

    .layout__main-content.user-logged.cow-brand-content {
      padding-top: 170px;
    }

    // Static
    .layout__main-content.is-payment-summary-static {
      padding-top: 55px;
    }

    // ============

    .cow-payment_summary_mobile--static.user-logged--brand {
      margin-top: 112px;
    }

    .cow-payment_summary_mobile--static.user-logged {
      margin-top: 56px;
    }

    .cow-payment_summary_mobile.cow-payment_summary_mobile--collapsible.user-logged {
      margin-top: 56px;
    }

    .cow-payment_summary_mobile.cow-payment_summary_mobile--collapsible.user-logged--brand~.layout__main-content.user-logged.cow-brand-content {
      padding-top: 205px;
    }

    .brand.brand--mobile.user-logged.not-navbar
      ~ .cow-payment_summary_mobile--collapsible
      ~ .layout__main-content.user-logged.cow-brand-content {
      margin-top: 37px;
    }

    .brand.brand--mobile.user-logged {
      margin-top: 56px;
    }

    .button-wrapper {
      margin-top: 24px;
    }

    #third-party-disclaimer {
      margin-top: 16px;
    }

    // === DISCOUNT MODAL ===
    .discount-modal {
      .generic-modal__content {
        // TODO - TECH DEBT: if we change will affect our actual color because it's
        // not the same color in those variables and it's not something/issue related to
        // the New Card Initiative, we can tackle and improve this in the Rebranding initiative
        // using variables and colors from Andes
        color: #999;

        // TODO: REMOVE THIS AND FIX THE text-align defined in the generic-modal of the COW Core Components lib
        text-align: unset !important;
      }
    }
  }
}
