@import "shared";

.layout-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.layout--redirect .layout-main {
  .page-mask{
    display: flex;
    flex-direction: column;
  }
}

.pref-expired,
.pref-unavailable {
  .oops__message {
    font-size: em(18);
  }
}
