@import "shared";

form.group_form {
  .group-coupon {
    &,
    .ui-card-wrapper {
      margin-bottom: em(16);
    }

    .ui-card {
      margin-bottom: 0;
      padding: em(16);

      .group-row label.andes-form-control.ui-input-text {
        margin-left: 0;
      }
    }

    &.applied .ui-card {
      padding: 0 em(32);
    }
  }
}
