@import "shared";

#login-section #logo-container {
  justify-content: center;
}

#login-section #logo-container .icon-wrapper {
  width: em(40);
}

#login-section .c-title {
  line-height: em(19.2);
}

#login-section .c-title,
#protected-purchase {
  margin: em(8) em(24);
}

#login-section .icon-shield-bpp {
  height: em(12);
  width: em(11);
  margin-right: em(4);
}

#protected-purchase,
#footer-content {
  justify-content: center;
  align-items: center;
}

#protected-purchase .text {
  color: $cow-color-success;
}

#login-list .icon-wrapper {
  align-items: flex-start;
}

#login-list .icon-wrapper svg {
  margin-top: em(5);
}

#login-list .item .text {
  color: rgb(0 0 0 / 55%);
}

#login-section #login_button {
  font-size: em(16);
}

.options-list {
  margin-bottom: 0;
}

.options-list .item-guest .options-list__label .title {
  margin-bottom: em(2);
}

.options-list .item-guest .options-list__label {
  padding: em(20) em(24);
}

#guest-option-bottomRow {
  padding-left: em(24);
  padding-right: em(24);
}

#guest-option-bottomRow .text {
  font-size: em(11);
  font-weight: 400;
  line-height: em(11);
  color: $cow-dark-gray;
}

#footer-content {
  font-style: normal;
  font-weight: 500;
  font-size: em(11);
  line-height: em(12);
  gap: em(2);
  margin-top: em(24);
}
