@import "shared";

#group_switch_alternative_payment_method .ui-card {
  padding: 24px;

  .icon-wrapper {
    width: 40px;
  }

  p.text {
    margin-left: 16px;
  }
}

#change_payment_form > ul > li > .options-list__label {
  padding: 24px;
}

.group-generic-block {
  .form .ui-card-wrapper .ui-card {
    .icon-wrapper {
      margin-left: 0 !important;
    }
  }
}

#group_pay {
  .ui-card.split-amount.disabled {
    & + #installments_row {
      & + #cvv_row {
        .row_cvv {
          padding: em(16);
        }
      }
    }
  }
}

// This styles is to clean the contamination of the Split Payment Span text
.split-amount .andes-form-control__bottom {
  margin-bottom: 0;
}

#extra_data_form {
  .ui-card {
    overflow: unset;
  }
}

.card-hint {
  display: none;
}

.input-cvv {
  width: 100%;
}

.andes-form-control {
  &.andes-dropdown,
  &.andes-form-control--textfield {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title-h2 {
  text-align: left;
  margin-top: 0;
  font-size: em(14);
}

.chevron-left {
  transform: scaleX(-1);
}

.group-back-url {
  .chevron-left {
    transform: unset;
  }
}

#group_content > .c-title:not(#title_combination) {
  display: inline-block;
  font-size: $cow-font-20;
  line-height: 25px;
  margin: 40px 0 8px;
  width: 100%;
}

// Installments
#group_pay {
  .group-generic {
    display: flex;
    flex-wrap: wrap;

    .icon-wrapper {
      margin-right: 16px;
      width: inherit !important;
    }

    .select-additional-info {
      margin-top: em(-6);
    }
  }

  #installments_group_generic {
    .andes-dropdown__trigger {
      justify-content: flex-start;
    }
  }

  .ui-card {
    overflow: visible;
    flex-wrap: nowrap;
    z-index: 2;

    .andes-message--quiet {
      margin-top: em(38);
    }
  }

  .row_cvv {
    justify-content: start;

    .icon-wrapper {
      &.icon-card-cvv--virtual {
        .icon-wrapper {
          margin-left: 0;
        }
      }
    }
  }

  .select-installments {
    .andes-list__item {
      padding: 0 em(16);

      .andes-list__item-first-column {
        padding: em(12) 0;

        .andes-list__item-text {
          display: flex;
          flex-direction: column;
          padding: unset;
        }
      }
    }
  }
}

.layout__col-content {
  padding-bottom: em(20);
}

.c-title ~ .group-row {
  padding-left: em(24);
}

.group_row_selectable {
  border-radius: 0 0 em(4) em(4);

  .group-row {
    padding-left: em(24);
  }
}

h1.c-title + div.toggle-button-wrapper {
  margin-bottom: em(24);
}

.c-title + .group-generic:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: em(18);
  margin-top: em(-18);

  p {
    margin-right: em(16);
  }

  a.bank-deals-link {
    position: static;
    left: 0;
    float: none;
    transform: none;
    padding: 0;
    margin: 0;
    margin-right: em(16);
  }
}

.sticky-container {
  &.bottom {
    position: fixed;
    padding-left: em(16);
    padding-right: em(16);
  }
}

.group-coupon {
  label.andes-form-control {
    width: 100%;
  }
}

#shield_footer.group-generic {
  padding-top: em(24);
  line-height: 1;
}

.sticky-container {
  #shield_footer.group-generic {
    padding-top: em(8);
    line-height: 1;
  }
}

.group-generic-block {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
}

.group-generic-block + .group-row {
  .group-media-object {
    padding: 0;
    max-width: unset;
    margin-right: 0;

    .group_form .andes-button {
      text-align: left;
    }
  }
}

ul.select_row {
  .options-list__item {
    height: auto;
  }
}

@media (width <= 425px) {
  .andes-list__item {
    flex-wrap: wrap;

    .andes-list__item-first-column {
      flex-grow: 4;
      flex-basis: 61%;
    }

    .option-additional-info {
      margin-left: 0;
      line-height: 0;
      padding: em(10) 0 0 0;
      flex-basis: 40%;
    }
  }
}

@media (width <= 466px) {
  #installments_group_generic {
    .select-installments {
      max-width: 80%;
    }
  }
}

@media (width <= 287px) {
  #installments_group_generic {
    .select-installments {
      max-width: 83%;
    }
  }
}

div.cow-secure-fields {
  min-width: auto;
}

/** Styles for express combination */

.sticky-summary {
  border-bottom-right-radius: em(8);
  border-bottom-left-radius: em(8);
  box-shadow: 0 em(6) em(16) 0 rgb(0 0 0 / 10%);
}

#title_combination {
  text-align: left;
  font-size: $cow-font-12;
  margin-top: 60px;
  margin-bottom: 8px;
}

.payment-detail {
  &__row {
    margin-bottom: em(16);
  }
}

#group_form_combination {
  display: flex;
  flex-wrap: wrap;

  .payment-method-info {
    width: 100%;
    order: 1;

    .description {
      padding: 24px 24px 10px;
    }
  }

  #installments_row {
    order: 3;
    width: 100%;

    .ui-card {
      padding: 6px 24px 24px;
      margin-bottom: 0;
      border-bottom-right-radius: em(6, 16);
      border-bottom-left-radius: em(6, 16);

      .select-installments {
        max-width: 100%;
      }
    }
  }

  #cvv_row {
    order: 2;
    width: 100%;

    .ui-card {
      padding: 0 24px;
      margin-bottom: 0;

      .row_cvv {
        div.cow-secure-fields {
          min-width: 100%;
        }
      }
    }
  }

  // border case when installment is not exist
  .payment-method-info + #cvv_row {
    .ui-card {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      border-bottom-right-radius: em(6, 16);
    }
  }
}

#use_consumer_credits button {
  font-size: em(14);
  margin-bottom: 0;
}
