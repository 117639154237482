@import "shared";

.options-list__label {
  padding: 16px 24px 16px 24px;

  &:active {
    background: $cow-lighter-gray;
  }
}

.step_payment_option_form .options-list__label::before {
  height: em(6);
  width: em(6);
}

.options-list {
  &__item {
    &.item-account_money_row {
      height: auto;
    }

    &.disabled {
      &.item-account_money_row .options-list__label {
        .group-media-object div + div {
          .icon_with_tooltip .andes-tooltip__trigger .icon-wrapper svg {
            width: em(20);
            height: em(20);
          }
        }
      }
    }
  }

  &__label {
    &.with-switch {
      .andes-switch {
        margin-right: 0;
      }
    }
  }
}
