@import "shared";

.group-legals {
  display: flex;
  flex-direction: column;
  padding-top: em(12);
  margin-bottom: em(24);
  text-align: center;

  .mp-brand {
    order: 2;
    margin-top: em(8);
  }

  > a {
    display: block;
    font-size: em(12);
    margin-top: em(8, 12);
    text-decoration: none;
  }

  .group-generic > a {
    display: block;
    font-size: em(11);
    margin-bottom: em(12, 11);
  }
}
