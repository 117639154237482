@import "shared";

.open-finance-terms {
  display: flex;
  justify-content: center;
}

.group-summary__container {
  .open-finance-terms {
    display: none;
  }
}

.open-finance-terms {
  span {
    color: $andes-gray-550;
  }
}
