@import "default";

&_template {
  &_guest {
    .layout--new-interface {
      justify-content: space-between;

      .guest-email {
        margin: em(26) 0 em(24) 0;

        .title {
          margin-bottom: 16px;
          font-size: $cow-font-18;
          font-weight: 600;
          line-height: 1.3;
        }

        .andes-card__content {
          padding: em(24) !important;
        }
      }

      .bank_interests {
        margin: $cow-spacing-32 0 $cow-spacing-32 0;
      }

      #card-option__spei {
        margin-bottom: $cow-spacing-32;
      }

      .cow-payment_summary_mobile--collapsible {
        .andes-accordion-header-container__title {
          .andes-list__item-second-column {
            margin-right: -8px;
          }
        }
      }

      .layout__main-content {
        padding-bottom: em(40) !important;
      }

      .content {
        margin-bottom: 0 !important;
      }

      .cow-sticky__pay--default {
        position: sticky;
      }

      .cow-payment_summary_mobile--static {
        + .layout__main-content.is-payment-summary-static {
          padding-top: 58px;
        }
      }
    }

    #pix-row {
      .card-option__disclaimer {
        .andes-card__content {
          padding: 0 !important;
        }
      }
    }
  }
}
