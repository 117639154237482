@import "shared";

.layout__col-content {
  & > h1 {
    margin-top: 40px !important;
    text-align: center;
  }
}

&.redirect-view {
  max-width: 100%;
  overflow-x: hidden;
}

.card-form-group {
  display: flex;
  flex-wrap: wrap;

  > :nth-child(n) {
    flex: 1;
  }

  > .icon-wrapper {
    flex: none;
    margin-right: em(16);

    width: em(48);
    height: em(48);
    margin-top: em(18);
    margin-bottom: auto;
  }

  > .icon-card-cvv {
    width: em(46);
    margin-left: em(-10);
    margin-right: em(16);
  }

  .andes-form-control__embedded {
    visibility: hidden;
  }

  .icon-installments {
    span {
      font-size: em(20);
    }
  }

  .select-installments {

    .andes-dropdown__trigger {
      justify-content: space-between;
      margin-bottom: 0;
      span {
        text-align: left;
        max-width: em(200);
      }
    }
  }
}

.ui-card {
  overflow: visible;
  padding: em(24);
}

.layout__col-content {
  padding-top: 140px;
  padding-bottom: em(64);

  // forms buttons
  > .group-generic {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 24px;
    margin-bottom: em(16);

    .andes-button--transparent {
      width: 100%;
      margin-top: 8px;
    }
  }
}

&.step_second_split_payment {
  .sticky-summary.open .sticky-summary__row-container {
    padding-top: 0;
    border-top: none;

    .sticky-summary__text {
      font-weight: normal;
      font-size: $cow-font-14;
    }

    .sticky-summary__price {
      font-weight: normal;
      font-size: $cow-font-14;
    }
  }
}

.card-number-with-issuer__group {
  align-items: flex-start;

  .icon-wrapper {
    margin-top: em(25);
  }
}

.card-form-group {
  flex-wrap: nowrap;
  align-items: center;

  .icon-wrapper.icon-card-cvv {
    margin-left: 0;
    padding: 0;
  }
}


.ui-card.split-amount.disabled {
  & ~ .ui-card {
    .group-generic {
      margin-bottom: em(12);
    }
  }
}

// This styles is to clean the contamination of the Split Payment Span text
.split-amount .andes-form-control__bottom {
  margin-bottom: 0;
}

// TODO: Move this Styles to the Split amount input component
.split-amount.disabled {
  margin-bottom: em(40);
}
.ui-card.split-amount {
  & ~ .ui-card {
    .group-generic {
      margin-bottom: em(12);
    }
  }
}

// TODO: We should move this Styles to the corresponding component? (SelectExpirationDate)
.expiration-date__inner_wrapper .pick-year .andes-floating-menu {
  width: 100%;
}

.andes-card {
  .andes-card__content {
    padding: 0 24px 24px 24px;
    .group-generic {
      margin-bottom: 0;
    }
    .andes-form-control {
      margin-top: 32px;
    }

    .input-fullname {
      margin-top: 30px;
    }

    .input-cvv {
      margin-top: 22px;
    }

    .input-document {
      margin-top: 0;
    }
  }
}
