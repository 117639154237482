@import "./shared";

.cow-secure-fields {
  margin-bottom: em(16);
  .andes-form-control__field {
    height: em(48);
  }

  .andes-form-control--textfield .andes-form-control__bottom {
    margin-bottom: em(0);
  }
}

.cow-secure-fields.security-code {
  margin-bottom: em(8);
}