@import "shared";

.navbar.user-header {
  position: fixed;
  left: unset;
  top: 0;
  z-index: 300;
}

.user-header {
  &__content {
    height: em($navbar-mobile-height);
  }
}
