@import "shared";

.form_personal_info_pse {
  .form_personal_double_field {
    max-width: 100%;
    flex-direction: column;

    input {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .form_personal_field {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .form-actions {
    margin-top: $cow-spacing-20;

    button {
      margin: 4px 0;
    }
  }
}

.form_personal_info_document_type_tooltip {
  max-width: em(248);
}

:not(.legacy-mp-theme) {
  .form-actions {
    margin-top: $cow-spacing-20;
    padding-bottom: em(6);

    .andes-button {
      padding: 0;
      margin: 4px 0;
    }
  }
}

.rebranding {
  &__title {
    margin: em(18) 0 em(32);
  }
}
