@import "default";

.review-express {
  &__collapsed-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      padding: $cow-spacing-8 0;
    }

    & > div:last-child {
      width: 100%;
      padding: $cow-spacing-8 0;
    }

    & > div:only-child {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    #installments-Mensualidades-trigger {
      .andes-dropdown__chevron {
        position: relative;
        top: 2px;
      }
    }

    .security-code-tooltip {
      .andes-tooltip {
        &__trigger {
          margin-right: 12px;
        }
      }
      .icon-wrapper {
        width: 16px;
      }
    }
  }

  &__consumer-credits {
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      & > span:not(:first-child) {
        line-height: 15px;
        margin-top: 32px;
      }
    }

    &__modal {
      &__content {
        width: 100%;
        height: 100%;

        iframe {
          display: block;
          border: solid em(1) $cow-translucent-lighter-gray;
        }
      }
    }
  }

  &__change-payment-method {
    border: none;
    padding: $cow-spacing-16 $cow-spacing-24;

    &--single {
      border-top: 1px solid $cow-translucent-lighter-gray;
    }
  }

  &__bank_interests {
    margin: $cow-spacing-16 0 $cow-spacing-24 0;
  }
}

.andes-card {
  &__footer-arrow {
    width: 8px !important;
    height: 8px !important;
    margin-top: 6px !important;
  }
}

.footer-terms {
  margin-top: 32px !important;
}
