@import "shared";
@import "../express/styles.mobile";

#group_payment_method > ul > li > .options-list__label {
  padding: 24px;
}

.layout__col-content {
  p.text {
    margin: em(24) em(0);
    text-align: center;
    line-height: 15px;
  }
}

.title-h2 {
  text-align: left;
  font-size: em(14);
  margin-top: 0;
}

#group_pay {
  #installments_row {
    .ui-card {
      overflow: unset;
      padding-left: 24px;
      padding-top: 24px;

      #installments_group_generic.group-generic {
        .icon-wrapper {
          min-width: em(40);
          margin-top: 0;
        }
      }

      #amount_text_credits {
        margin-left: em(61);
        margin-top: em(-22);

        p {
          text-align: left;
        }
      }
    }
  }
}

/* Styles for consumer credits combination */

.sticky-summary {
  border-bottom-right-radius: em(8);
  border-bottom-left-radius: em(8);
  box-shadow: 0 em(6) em(16) 0 rgba(0, 0, 0, 0.1);
}

#title_combination {
  text-align: left;
  font-size: $cow-font-12;
  margin-bottom: 8px;
  margin-top: 40px;
}

.payment-detail {
  &__row {
    margin-bottom: em(16);
  }
}

.payment-method-info {
  margin-bottom: em(-1);
  box-shadow: 0 0 em(2) 0 rgba(0, 0, 0, 0.12);
  .description {
    padding: 24px 24px 1px;
  }

  & + #installments_row {
    margin-bottom: em(8);
    .ui-card {
      padding: 4px 24px 24px;
      margin-bottom: 0;
      .select-installments {
        max-width: 100%;
      }
    }
    & + .installment-detail {
      color: $cow-translucent-medium-gray;
      & + .row-instructions {
        .text {
          margin: 0;
          color: $cow-translucent-medium-gray;
          margin-bottom: em(8);
        }
      }
    }
    & + .row-instructions {
      .text {
        margin: 0;
        color: $cow-translucent-medium-gray;
        margin-bottom: em(8);
      }
    }
  }
}

.group-generic-block {
  .row-instructions {
    margin-bottom: em(8);
    .text {
      font-size: $cow-font-14;
      color: $cow-translucent-medium-gray;
    }
    & + .text.gray {
      font-size: em(12, 16);
      margin-top: 0;
      margin-bottom: em(16);
      & + .text {
        font-size: em(12, 16);
        margin-top: 0;
        margin-bottom: em(16);
        color: $cow-translucent-medium-gray;
      }
    }
    & + .text {
      font-size: em(12, 16);
      margin-top: 0;
      margin-bottom: em(16);
      color: $cow-translucent-medium-gray;
    }
  }
  .installment-detail.large {
    & + .text {
      font-size: em(12, 16);
      margin-top: 0;
      margin-bottom: em(16);
      color: $cow-translucent-medium-gray;
    }
  }
}

.sticky-container.bottom {
  position: fixed;
  padding: em(16);
}
