@import "shared";

.feedback-screen.andes-feedback-screen--no-body {
    .andes-feedback-screen__wrapper {
        width: 100%;

        .andes-card {
            box-shadow: 0 em(1) em(2) 0 rgba(0, 0, 0, 0.12);
        }

        .andes-card__content {
            .andes-feedback-screen__header {
                padding-top: 0;

                .andes-feedback-screen__header-asset {
                    margin: em(-56) auto em(16);
                }

                .andes-feedback-screen__header-title {
                    font-size: em(20);
                    line-height: em(25, 20);

                    br {
                      @media (width <= 377px) {
                        display: none;
                      }
                    }
                }
            }
        }
    }
}
