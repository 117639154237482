.collapsible-selector-container.payment-option-selector {
  .andes-list__item {
    padding: em(24) em(16);

    .icon-wrapper {
      width: em(34);
      height: em(34);
      margin-right: em(18);
      margin-top: 0;
    }

    .andes-list__item-primary {
      font-size: em(16);
    }

    .andes-list__item-secondary {
      font-size: em(14);
    }

    .andes-list__item-tertiary {
      display: flex;
      text-align: right;

      .icon-wrapper {
        margin-right: 0;
        width: em(24);
      }
    }
  }

  .andes-list__item {
    &.title {
      padding: em(14) 0 em(14) em(24);
    }

    .andes-list__item-primary {
      // &> div {
      //   display: flex;
      //   align-items: center;

      //   .icon-wrapper {
      //     margin-right: em(20);
      //     margin-top: 0;
      //   }
      // }
    }

    .andes-list__item-tertiary .tertiary {
      display: flex;
      text-align: right;
    }
  }
}
