@at-root body.step_wallet_congrats_approved {
  &,
  .layout--wallet {
    background: $cow-color-success;
  }
}

@at-root body.step_wallet_congrats_rejected {
  &,
  .layout--wallet {
    background: $andes-error-red;
  }
}

.layout-main {
  padding: 0;
}

.lottie-container {
  position: relative;
  height: em(375);

  .lottie-wrapper {
    position: relative;
    height: em(375);
  }

  div[aria-label="animation"] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  div.spacer {
    height: em(213);
  }

  span.animation-text--show,
  span.animation-text--hide {
    display: block;
    z-index: 2;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: em(20);
    color: $cow-white;
    font-weight: bold;
    padding: em(16) em(24) 0 em(24);
  }

  span.animation-text--hide {
    opacity: 0;
  }

  span.animation-text--show {
    opacity: 1;
    transition: opacity 0.8s;
  }

  .group_form {
    display: flex;
    justify-content: center;
    margin-top: -5em;

    .andes-button.andes-button--transparent {
      color: $cow-white;
      border-color: $cow-white;
      max-width: fit-content;
      z-index: 1;
    }
  }
}
