@import "shared";

.congrats-header {
  margin: 0 em(-16);
  padding-top: em(62);
  padding-bottom: em(108);

  @media (max-width: em(320)) {
    & {
      font-size: em(13);
      margin: 0 -16px; // value needs to be in px
    }
  }
}

@mixin green-congrats-header-background {
  background: linear-gradient(
    180deg,
    rgba($cow-color-success, 1) 0%,
    rgba($cow-color-success, 1) em(56),
    rgba($cow-white, 0) em(144)
  );
  padding: em(56) em(16) 0;
}

@mixin green-congrats-footer-buttons {
  .group-row:last-child {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: em(16);

    #group_button_back_congrats {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: em(8);
      align-items: center;
      width: 100%;

      .chevron-left {
        margin: 0 em(19) 0 0;
      }

      .andes-button--link {
        margin: em(0);
        font-size: $cow-font-16;
        padding: em(14) 0;
        text-align: center;
        width: 100%;
        flex-shrink: unset;
      }
    }

    .andes-button--quiet {
      font-size: $cow-font-16;
      min-width: em(142);
      padding: em(14) 0;
      text-align: center;
      width: 100%;
    }
  }
}

.congrats--instructions {
  @include green-congrats-footer-buttons;

  .congrats-header {
    @include green-congrats-header-background;

    &__container {
      margin: 0 auto;

      .congrats-titles {
        width: 100%;
      }
    }
  }

  #group_card_ui_top {
    .ui-card {
      padding: em(16);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .group-button {
    .andes-button--loud {
      font-size: $cow-font-16;
      width: 100%;
    }

    .andes-button--loud + .andes-button--link {
      width: 100%;
      text-align: center;
      font-size: $cow-font-16;
    }
  }

  .andes-button--loud + textarea + .andes-message.andes-message--neutral.andes-message--quiet {
    margin-top: em(16);
  }

  .congrats-content {
    hr.separator-full {
      width: 100%;
    }

    .qr_code_img + .andes-button--link {
      width: 100%;
      margin: auto;
    }

    .ordered-list li {
      #number_wrapper {
        border: solid 0.1px $cow-black;
        border-radius: 50%;
        height: em(28);
        flex-grow: 0;
        flex-shrink: 0;
        width: em(28);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .row-instructions {
      .icon-with-tooltip {
        flex-grow: 20;
        margin-left: em(10);

        .andes-tooltip__trigger {
          margin-left: em(0);
        }
      }
      .andes-button--link{
        padding: 0;
      }

      .cow-button-external-link {
        width: 100%;
      }
    }

    #ui_card_group {
      .ui-card {
        padding: em(16);
      }
    }

    #download_app_ui_card .ui-card {
      .row-instructions {
        .text {
          font-size: $cow-font-14;
          color: $cow-text-color-secondary;
        }
      }
      .andes-button#awareness_download_button_id {
        margin-bottom: 0;
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-semibold;
      }
    }
  }
}

.congrats--recover {
  .congrats-content {
    .group-media-object {
      margin-bottom: em(24);
      align-items: start;
    }

    #email_guest_login {
      .row-details {
        .title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: em(210);
        }
      }
    }
    #group_pay
      #cvv_row
        .ui-card
          .row_cvv {
            padding: em(20) 0;
            width: 100%;
          }

    #card_media_cvv {
      align-items: center;

      &~.input-cvv,
      &~.security-code {
        width: 100%;
        margin-bottom: 0;

        .andes-form-control__embedded {
          display: none;
        }

        .andes-form-control__bottom {
          margin: 0;
        }

        .andes-form-control__error-icon,
        .andes-form-control---error-icon {
          margin: em(4) em(6) 0;
        }

        .andes-form-control__message {
          margin: em(4) 0 0 0;
        }
      }

      &~.andes-button--loud {
        margin: em(24) 0 0 0;
      }
    }

    .group-button {
      #button_guest_login {
        margin-bottom: em(8);
      }

      .andes-button {
        width: 100%;
      }
    }

    .ui-input-text {
      width: em(163);
      margin-bottom: em(53);

      &__input,
      &__status-bar {
        width: 100%;
      }
    }
  }

  .ordered-list {
    padding: em(8, 14) 0 em(32, 14) 0;

    .text {
      padding-left: em(24, 14);
    }
  }

  .group_form, .group-button{
    button.andes-button {
      margin-bottom: 8px;
    }
  }
}

.step_congrats_remedy_offer {
  .group-button {
    display: contents;
  }

  .andes-button--loud {
    margin: 0 auto;
  }
}

.step_congrats_remedy_offer {
  .group-button {
    display: contents;
  }

  .andes-button--loud {
    margin: 0 auto;
  }
}

.step_congrats_call_for_auth_authorize .ui-card #group_form {
  .andes-button[type="submit"] {
    margin-bottom: em(8);
  }
}

.step_congrats_bad_security_code,
.step_congrats_bad_date {
  .congrats {
    .ui-card {
      margin-bottom: 0;
    }

    .group-back-url {
      display: flex;
      justify-content: center;
      padding: em(24) 0;

      .chevron-left {
        margin: 0 em(6);
      }

      .andes-button {
        margin: 0;
        line-height: em(14);
        width: unset;
        min-height: 1.25em;
      }
    }
  }
}

.congrats.congrats--redirect {
  .congrats-content {
    .title-h3 {
      width: em(327);
    }
  }
}

.congrats--approved {
  @include green-congrats-footer-buttons;

  .congrats-header {
    @include green-congrats-header-background;

    .congrats-titles {
      width: 100%;
      margin-top: 0;

      #group_card_ui_top {
        .ui-card {
          padding: em(16);
          margin-top: 0;

          .group-row {
            .c-title {
              text-align: left;
              margin-bottom: em(8);
            }

            p.text {
              color: rgba($cow-black, 0.55);
              font-size: $cow-font-14;
              margin-bottom: em(0);
            }
          }
        }
      }
    }

    #group_card_ui_top {
      .ui-card {
        margin-bottom: 0;
      }
    }
  }

  #download_app_ui_card .ui-card {
    flex-direction: column;

    .row-instructions {
      margin-bottom: em(8);

      .text {
        margin-top: em(8);
        font-size: $cow-font-14;
      }
    }

    .andes-button {
      margin-bottom: 0;
    }
    #download_app_button_with_info.andes-button {
      text-indent: 0;
      &::before {
        content: none;
      }
    }
  }

  .group-generic {
    #voucher_button {
      margin-top: em(32);
      font-size: $cow-font-16;
      width: 100%;
    }
  }

  #auto_return_row {
    margin-top: em(32);

    .autoreturn {
      margin-bottom: 0;
    }
  }

  .group-congrats-shipping {
    #group_card_media {
      .group-congrats-shipping {
        position: relative;

        .andes-badge {
          position: absolute;
          left: -63px;
          top: 30px;
          min-width: 50px;
          margin: 0;

          p {
            display: block;
            text-align: center;
          }
        }
      }
    }
  }

  #easy_login_header {
    .icon-wrapper + .row-instructions {
      .title-h2 {
        padding-right: 10%;
      }
    }

    .row-instructions {
      .text {
        text-align: unset;
      }
    }
  }

  #easy_login_button_id {
    margin-top: em(29);
    width: 100%;
  }

  .congrats-content {
    #group_qr_media {
      svg {
        path {
          fill: $andes-text-color-primary;
        }
      }
    }
  }
}

[class*="step_congrats_express_recover_"],
[class*="step_congrats_insufficient_amount"] {
  .congrats--recover {
    .c-title {
      line-height: em(25, 20);
    }

    .group-generic {
      width: 100%;

      #ui_card_group {
        .group-generic-block .group-row {
          .group-media-object {
            align-items: center;
          }

          + .title-h2 {
            margin-bottom: 0;
          }
        }

        #group_pay.group_form {
          flex-direction: column;
          gap: $cow-spacing-24;

          .group-flex {
            margin-top: em(24);
          }

          #installments_group_generic {
            .andes-dropdown {
              max-width: 100%;
            }

            .select-installments {
              margin-left: 0;

              #installments_select_express {
                margin-top: 0;
              }

              .select-additional-info {
                margin-top: em(4, 14);
              }
            }

            .icon-wrapper {
              max-width: 20%;
              margin-right: em(28);

              .icon-installments {
                left: em(2);
              }
            }
          }

          .ui-card-wrapper {
            .ui-card {
              margin-bottom: 0;

              .row_cvv {
                padding: 0;

                .input-cvv.ui-input-text {
                  width: 100%;
                  margin-bottom: 0;
                }

                .security-code {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .row-instructions {
          .text {
            text-align: center;
          }

          .group-button {
            display: block;
            margin-top: $cow-spacing-24;

            +.text {
              padding-bottom: $cow-spacing-40;
            }

            .andes-button.andes-button--quiet {
              margin-left: 0;
            }

            #group_redirect_button {
              button {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .installments_row {
      .options-list__label {
        padding: 0;
      }

      .group-media-object {
        margin: 0;
        align-items: center;

        > div:first-child {
          margin-right: em(8);
        }
      }

      .options-list__item {
        min-height: min-content;
      }
    }

    .group-back-url {
      width: auto;
      justify-content: center;
    }
  }
}

[class*="step_congrats_express_recover_consumer_credits"] {
  .congrats--recover {
    #ui_card_group {
      .ui-card {
        #group_pay.group_form {
          gap: 0;

          .title-h2 {
            margin-top: 0;
          }

          #installments_select_credits {
            margin-bottom: 0;
          }
        }

        .installment-detail {
          p.installment-detail__content {
            text-align: left;
          }

          p.installment-detail__content.large {
            padding-bottom: em(24, 24);
          }

          p.installment-detail__content.small {
            padding-bottom: em(8, 14);
          }
        }

        .row-instructions {
          margin: 0;
        }
      }
    }
  }
  .terms-and-conditions {
    &.remedies_terms_and_condition {
      padding-bottom: 34px;
      margin-top: -4px;
    }
  }
}

[class*="step_congrats_express_recover_account_money"] {
  .congrats--recover {
    #ui_card_group .ui-card {
      .row-instructions .group-button {
        margin-top: 0;
      }
    }
  }
}

.congrats--rejected {
  .congrats-header {
    background: linear-gradient(
      180deg,
      rgba($cow-color-error, 1) 0%,
      rgba($cow-color-error, 1) em(80),
      rgba($cow-color-error, 1) em(81),
      rgba($cow-white, 0) em(168)
    );
    padding: em(80) em(16) 0;

    .congrats-titles {
      width: 100%;
      margin-top: 0;

      #group_card_ui_top {
        .ui-card {
          padding: em(16);
          margin-top: 0;

          .group-row {
            .c-title {
              text-align: left;
              margin-bottom: em(8);
            }

            p.text {
              color: rgba($cow-black, 0.55);
              font-size: $cow-font-14;
              margin-bottom: em(0);
            }
          }
        }
      }
    }
  }

  .congrats-content {
    margin-top: em(16);

    #group_card_ui {
      .ui-card {
        padding: em(16);
      }
    }
  }
}

.step_congrats_call_for_auth {
  .congrats--recover {
    .ui-card {
      .title-h2 {
        margin-bottom: em(16, 18);
      }

      .andes-button--loud {
        margin-bottom: em(8);
      }

      .andes-button--quiet {
        margin-bottom: 0;
      }
    }
  }
}

.step_congrats_call_for_auth_authorize {
  .congrats--recover {
    .ui-card {
      #card_media {
        align-items: center;

        &~.input-cvv,
        &~.security-code {
          width: 100%;
          margin-bottom: 0;

          .andes-form-control__embedded {
            display: none;
          }

          .andes-form-control__bottom {
            margin-bottom: 0;
          }
        }

        &~.andes-button--loud {
          margin: em(24) 0 0 0;
        }
      }
    }

    .group-back-url {
      width: 100%;
    }
  }
}

[class*="step_congrats_rejected"] {
  .congrats.congrats--rejected {
    .group-flex {
      display: flex;
      flex-direction: column;

      form {
        button {
          margin-top: $cow-spacing-24;
        }

        &:last-child button {
          margin-top: $cow-spacing-8;
        }
      }
    }
  }
}

.legacy-mp-theme {
  .congrats--approved {
    .ui-card {
      #incentive_download_button_id {
        text-indent: 32px;
      
        &::before {
          content: "";
          position: absolute;
          top: em(8);
          width: em(45);
          height: em(32);
          margin-left: em(-38, 14);
          background: url("../assets/images/logo-mp-small@2x.svg") no-repeat;
          background-size: cover;
          left: initial;
          transform: scale(0.74);
        }
      }
    }
  }
}

.congrats-content {
  button.andes-button {
    width: 100%;
  }
}
