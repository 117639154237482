@import "shared";

.layout__main-content {
  & > .andes-typography {
    text-align: center;
  }
}

.options-combinations {
  .andes-card.payment-method-card-list {
    .andes-list {
      .andes-list__item {
        padding: 16px 24px !important;

        .andes-list__item-selection-control {
          .andes-radio {
            padding-left: 0;
            margin-left: -8px;
          }
        }
      }
    }
  }

  .payment-detail__row {
    .payment-detail__content {
      padding: 16px 24px;
    }
  }
}

.layout__col-sidebar {
  .cow-payment_summary_mobile, .brand {
    position: static;
  }
}

.sticky-container-placeholder  {
  margin-top: 24px;
  height: 48px;
  .sticky-container {
    &.no-fixed {
      padding: 0 0 16px;
      .andes-button--quiet {
        margin-bottom: 0;
      }
    }
  }
}

.cow-footer {
  margin-top: auto;
  padding-top: 40px;
  padding-bottom: 16px;
}
