@import "shared";

#summary-list {
  .andes-list__item-primary {
    .secondary-text {
      .icon-with-tooltip {
        margin-left: em(4);
      }
    }
  }
}
