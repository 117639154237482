@at-root html {
  overflow: scroll;
  position: absolute;
  inset: 0;

  body {
    &.step_wallet_payment,
    &.step_wallet_card_form {
      background: $cow-white;
      overflow: scroll;
      height: 100%;

      div.layout--wallet {
        position: relative;
      }
    }
  }
}

.group-row {
  background-color: white;
}

.form.group_form {
  flex-direction: column;
  padding: 0;
  box-shadow: 0 em(8) em(12) 0 rgb(52 131 250 / 12%), 0 0 em(4) 0 $cow-translucent-lighter-gray;
  border-radius: em(4);

  &.collapsible-open {
    box-shadow: none;
  }

  .group-generic {
    font-weight: 400;
    line-height: 1;
    background-color: $cow-white;
    display: flex;
    padding-left: em(12);
    flex-direction: row;
    border-radius: 0;
    box-shadow: none;
    border-top: 0.0625em solid $cow-medium-gray;

    .icon-wrapper {
      margin-right: em(10);
      width: em(50);
    }

    .card-hint {
      display: none;
    }

    .input-cvv {
      width: 100%;
      padding-right: em(24);
      margin-bottom: em(27);
      padding-top: em(21);
    }
  }
}

.group-row .icon-wrapper {
  border: none;
  height: unset;
  margin-right: unset;

  svg {
    max-width: unset;
    max-height: unset;
  }
}

.group-row:first-of-type .icon-wrapper:first-of-type {
  width: em(102);
}

.group-row:first-of-type {
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0;
  min-height: 0;
  border-bottom: 0.0625em solid $cow-translucent-lighter-gray;
  border-radius: 0.25em 0.25em 0 0;
}

.group-wallet-logout {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: white;

  .icon-wrapper {
    width: em(48);

    img {
      border-radius: 50%;
    }
  }

  h1.c-title {
    padding: 0 em(32);
    font-size: $cow-font-20;
  }

  #button_back_chevron {
    position: absolute;
    transform: rotate(180deg);
    top: em(16);
    left: em(-14);

    .icon-wrapper {
      width: em(32);
    }
  }
}

.form.group_form:last-of-type {
  box-shadow: none;

  .group-generic {
    background: transparent;
    padding: 0;
    border: none;

    .andes-button.andes-button--loud:last-of-type {
      margin: em(16) em(1) 0 em(1);
    }
  }

  &.collapsible-open {
    .andes-button.andes-button--loud:last-of-type {
      display: none;
    }
  }
}

:checked + .options-list__label {
  box-shadow: none;
}

.collapsible-selector-container.unfolded {
  z-index: 200;
}

.item-new_card_row {
  #new_card_row + .options-list__label {
    box-shadow: none;
    padding: em(7) em(16);

    &::after {
      left: 0;
    }
  }
}

.wallet-summary.collapsible + .form.group_form.collapsible-open .collapsible-selector-container {
  top: em(130);
}

.wallet-summary.collapsible.open + .form.group_form.collapsible-open .collapsible-selector-container {
  top: em(320);
}

.wallet-summary.closed {
  margin-bottom: 0;
}

.logout-row {
  text-align: right;
}

@media (width <= 320px) {
  div.layout--wallet {
    .layout-main {
      padding: 0 em(8);

      .group-row:first-of-type {
        padding: 0 em(16);
      }
    }
  }
}
