@import "shared";

.payment-method-info {
  .description {
    padding: em(16);
  }

  .amount-details {
    padding: em(14) em(16);
  }
}
