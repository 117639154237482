@import "shared";

@at-root {

  body,
  .layout--redirect,
  .layout-main,
  .page-mask,
  .payment-options-mobile-screen {
    height: 100%;
  }
}


nav.cow-navbar {
  position: fixed;
  z-index: 300;
  height: em($navbar-mobile-height);
}

.payment-options-mobile-screen {
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .payment-options-mobile-screen__content-title {
      text-align: center;
      // TODO: investigar pq está maior que o normal
      padding: em(40, 20) 0 em(32, 20) 0;
    }

    .badge-list-item {
      border-bottom-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  &__content-card-list {
    img {
      // TODO: fix aria label in payment method component
      position: relative !important;
    }

    .cow-button-back-to-site {
      justify-content: center;
    }
    #pay-with-cash{
      margin-bottom: 16px;
    }
  }

  &__content-footer {
    padding-top: 40px;
    padding-bottom: 16px;
    margin-top: auto;

  }

  &--not-navbar {
    .sticky-summary {
      top: 0;
    }

    &:has(.navbar) {
      .navbar {
        top: 0;
      }

      .sticky-summary {
        top: 56px;
      }
    }
  }

  &--logged {
    .sticky-summary {
      top: 56px;
    }

    &:has(.navbar) {
      .navbar {
        top: 56px;
      }

      .sticky-summary {
        top: calc((56px) * 2);
      }
    }
  }
}

.layout__col-content {
  width: 100%;
}

.andes-coach-marks {
  button.andes-coach-marks__step__next-button {
    position: absolute;
  }
}
