@import "shared";

.modal-wrapper {
  .zipcode-finder {
    display: flex;
    flex-flow: column;
    gap: em(12);

    .andes-button--loud {
      margin-top: em(12);
    }

    .andes-dropdown {
      margin-right: 0;
    }

    .group-flex {
      flex-direction: column;

      .andes-autocomplete {
        width: 100%;

        &.andes-dropdown--open {
          .andes-dropdown__popover {
            max-height: em(200);
            overflow: scroll;
          }
        }
      }
    }
  }
}
