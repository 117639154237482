@import "shared";

body .optimus .group-generic-block {
  .form .ui-card-wrapper .ui-card {
    .icon-wrapper {
      margin-left: em(-6);
    }
  }

  .group-row .group-media-object .row-details {
    margin-left: em(6);
  }

  .group-row {
    padding: em(20) em(24);
  }

  // Installments
  #group_pay {
    #installments_group_generic {
      .text {
        margin-left: 61px;
      }
    }
  }

  #group_pay.form .ui-card-wrapper .ui-card {
    padding: em(20) em(24);
  }

  & + .row-instructions {
    margin: em(40) 0;

    .text {
      font-size: em(16);
    }
  }
}
