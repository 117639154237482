@import "shared";

.payment-detail__text {
  .icon-with-tooltip {
    margin-left: 8px;
    .andes-popper {
      margin-bottom: 0;
    }
  }
}
