@import "shared";

.sticky-container-placeholder {
  height: em(58); // to compensate pay button height
  position: relative;

  &.with-description {
    margin-top: em(76);

    /* Sticky submit button */
    & {
      padding: em(16) em(16) 0 em(16);
    }

    .text.gray {
      text-align: center;
      font-size: em(12);
      line-height: em(16);
      margin: em(12) 0 em(-4) 0;
    }

    .title-h2 {
      text-align: center;
    }

    &.bottom {
      height: em(112);
    }

    .andes-button {
      margin-bottom: em(4);
    }
  }
}

.sticky-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $gutter-base;
  line-height: em(65);
  z-index: 200;
  background-color: rgba($cow-white, 0.97);
  box-shadow: 0 em(-5) em(10) 0 $cow-translucent-transparent-gray;
  transition: background 250ms ease, box-shadow 250ms ease;
  transform: translateZ(0); // Use hardware acceleration for fixed elements for better fps in mobile

  &__row-container {
    display: flex;
  }

  &.bottom {
    background-color: transparent;
    box-shadow: none;
  }

  &.no-fixed {
    position: static;
  }
}
