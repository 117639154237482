@import "./shared";

.layout-terms {
  text-align: center;
  display: block;
  margin: em(24) 0 em(16);

  span,
  a {
    display: inline-block;
    font-size: em(14);
    margin-left: em(6, 14);
  }
}
