@import "shared";

.group-media-object .icon-wrapper {
  margin-right: em(18);
}

.group-media-object:only-child {
  .row-details {
    .title {
      max-width: $mobile-email-text-max-width;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.group-media-object div:nth-child(3) {
  .andes-badge.andes-badge--rounded-bottom-left {
    margin-top: em(3);
  }
}

.select_general_payment_options {
  .group-media-object div:nth-child(3) {
    .andes-badge.andes-badge--rounded-bottom-left {
      margin-top: em(0);
    }
  }
}
