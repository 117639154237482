@import "shared";

.modal-zipcode {
  width: 100%;

  .modal-zipcode-actions {
    flex-direction: column-reverse;

    button:first-child {
      margin-right: 0;
    }

    button:last-child {
      margin-bottom: $cow-spacing-16;
    }
  }
}
