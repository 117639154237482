.logout-row {
  font-size: em(14);
  color: $cow-translucent-dark-gray;
  display: flex;
  padding-top: em(18);
  padding-bottom: em(18);

  .user-avatar {
    .ui-avatar__content {
      width: em(28);
      height: em(28);
    }
  }

  .user-name {
    margin-right: em(8);
    align-self: center;

    span {
      color: $andes-accent-color;
    }
  }

  .icon-wrapper {
    width: em(28);
  }
}
