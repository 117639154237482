@import "shared";

.navbar {
  &:not(nav) {
    position: fixed;
    width: 100%;
    height: em($navbar-mobile-height);
    margin: 0 em(-16);
    z-index: 2;
  }

  // TODO: Remove this conditions when flows replaces Navbar with UserHeader
  &:not(.navbar--ml, .navbar--mp, nav.navbar) {
    background-color: $cow-white;
  }

  &__container {
    min-height: 100%;
    display: flex;

    .button-back-arrow {
      flex-shrink: 1;
      margin-top: em(25);
    }

    .brand {
      flex: 1;

      .brand__name {
        font-size: $cow-font-16;
      }
    }
  }

  &__brand-name {
    display: inline-block;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cow-navbar-v2+.navbar {
  top: em($navbar-mobile-height);
  position: fixed;
}

.navbar--ml {
  .navbar__logo {
    width: em(44);
    height: em(30.98); // very specific height because a bug in the image
    top: em(14);
    background-image: url("../assets/images/logo-ml-small@2x.png");
    background-size: em(44) em(31);
  }
}

.navbar--mp {
  .navbar__logo {
    width: em(44);
    height: 1.93em; // very specific height because a bug in the image
    top: em(14);
    background-image: url("../assets/images/logo-mp-small@2x.png");
    background-size: em(44) em(31);
  }
}

.step_review_split_payment,
.step_first_split_payment,
.step_second_split_payment {
  .navbar:not(nav) {
    z-index: 202;
  }
}
