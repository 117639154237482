@import "shared";

.ui-card {
  padding: em(24);
}

@media (width <=320px) {
  .ui-card {
    padding: em(16);
    margin-right: em(-16);
    margin-left: em(-16);
    border-radius: 0;
  }
}

// Message
// ----------------------------------------------
.andes-message {
  .andes-message__content {
    width: 74%;
  }
}

#card-option__spei .andes-message__content {
  width: 85%;
}

.installments-wrapper {
  .andes-message {
    .andes-message__content {
      width: 100%;
    }
  }
}

.legacy-mp-theme {

  // buttons
  button.andes-button:not(.andes-coach-marks__step__next-button),
  a.andes-button {
    display: block;
    width: 100%;
    text-align: center; // Fix for button links
  }

  .toggle-button-wrapper .toggle-button {
    width: auto;
  }
}

// buttons
:not(.legacy-mp-theme) {

  button.andes-button:not(.andes-coach-marks__step__next-button),
  a.andes-button {
    display: block;
    width: 100%;
    text-align: center; // Fix for button links
    margin-bottom: 8px;
  }

  .andes-modal__actions--fixed {
    display: flex;
    flex-wrap: wrap;

    button.andes-button {
      margin-bottom: 0;
    }
  }
}