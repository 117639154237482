@import "shared";

.brand {
  color: $cow-gray-800;
  justify-content: center;
  align-items: center;
  height: em($navbar-mobile-height);

  &__img {
    width: em(20);
    height: em(20);
  }

  &__name__small {
    font-size: em(16);
  }
}
