.buyfast {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: em(2) em(12);

  &.andes-badge--small.andes-badge--corner {
    border-top-right-radius: $cow-border-radius-4;
  }

  &.andes-badge--small .andes-badge__content {
    padding: em(3) 0;
  }

  svg {
    vertical-align: middle;
  }

  .andes-badge__content {
    font-size: em(12);
    font-weight: 700;
    line-height: em(12);
  }
}

.experiment.experiment--5100.step_payment_option_form {
  .ui-card {
    margin: em(10) 0 em(24) 0;
  }

  .title-h2 {
    margin-bottom: 0;
    font-size: em(12);
    line-height: em(18);
    letter-spacing: em(2);
    color: $cow-translucent-medium-gray;
    text-transform: uppercase;
  }

  .title-h2:first-of-type {
    color: $andes-accent-color;
  }

  .select_mp_login {
    .item-mp_login_row {
      height: auto;

      .options-list__label {
        padding: em(20) em(16);
      }
    }

    .row-details {
      margin-right: em(16);

      .title {
        margin-bottom: em(4);
        font-weight: 600;
      }
    }
  }
}

.experiment.experiment--5101.step_payment_option_form {
  .ui-card {
    margin: em(10) 0 em(24) 0;
  }

  .title-h2 {
    margin-bottom: 0;
    font-size: em(12);
    line-height: em(18);
    letter-spacing: em(2);
    color: $cow-translucent-medium-gray;
    text-transform: uppercase;
  }

  .select_mp_login {
    .group-media-object {
      flex-direction: column;
      text-align: center;

      .icon-wrapper {
        margin: 0 auto em(8) auto;
        width: em(46);
        height: em(46);
        padding: em(8);
        border-radius: 50%;
        background-color: $cow-lighter-gray;

        svg {
          width: em(27);
        }
      }

      &:only-child .row-details {
        .title {
          margin: 0 auto em(8);
          font-size: em(14);
          font-weight: 600;
        }

        .text {
          font-size: em(13);
        }
      }

      .text.centered {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: em(16) 0 0 0;
        padding: em(8);
        width: 100%;
        border-radius: em(5);
        background-color: $cow-transparent-blue;
        font-size: em(14);
        font-weight: 600;
        color: $cow-lighter-blue;
      }

      &:last-of-type {
        display: block;
        width: 100%;
      }
    }

    .options-list__item {
      height: auto;
    }

    .options-list__label {
      padding: em(20);

      &::before {
        display: none;
      }
    }
  }
}

.experiment.experiment--5102.step_user_type {
  .c-title {
    font-size: em(18);
  }

  .row-details {
    margin-right: em(50);

    .title {
      margin: 0 auto em(2);
      font-size: em(14);
      font-weight: 600;
    }

    .text {
      font-size: em(13);
    }
  }

  .options-list__item {
    height: em(92);
  }
}
