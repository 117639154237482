@import 'shared';

.layout--new-interface {

  height: 100%;
  display: flex;
  flex-direction: column;

  .brand {
    z-index: 100;
  }

  .layout__col-sidebar {
    z-index: 99;
  }

  .layout__col-sidebar, .brand {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .cow-payment_summary_mobile--static.user-logged {
      margin-top: unset;
    }
  }

  .cow-navbar-v2 + .brand {
    margin-top: #{$navbar-mobile-height}px;

    & + .layout__col-sidebar {
      margin-top: #{$navbar-mobile-height * 2}px;

      & + .layout__main-content {
        margin-top: #{$navbar-mobile-height * 3}px;
      }
    }
  }

  .cow-navbar-v2 + .layout__col-sidebar {
    margin-top: #{$navbar-mobile-height}px;

    & + .layout__main-content {
      margin-top: #{$navbar-mobile-height * 2}px;
    }
  }

  .brand + .layout__col-sidebar {
    margin-top: #{$navbar-mobile-height}px;

    & + .layout__main-content {
      margin-top: #{$navbar-mobile-height * 2}px;
    }
  }

  .cow-navbar-v2 + .layout__main-content {
    margin-top: #{$navbar-mobile-height}px;
  }

  .layout__col-sidebar + .layout__main-content {
    margin-top: #{$navbar-mobile-height}px;
  }

  .cow-back-link {
    justify-content: center;
    margin: 32px 0;
  }
}
