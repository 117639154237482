@import "shared";

.scroller {
  .ui-card {
    .group-flex {
      gap: em(12);
      flex-direction: column;
    }
  }
}

.layout--redirect,
.layout--embed {
  .layout__col-content > .modal-content {
    .modal-wrapper {
      .header {
        margin-right: 0;
        margin-left: 0;

        .title {
          flex: 1;
          font-weight: 600;
          font-size: em(32);
        }

        .cross {
          align-self: flex-start;
          margin-top: em(18);
        }
      }
    }
  }
}

.andes-snackbar {
  z-index: 11000;
}

.ui-card {
  overflow: initial;
  display: flex;
  flex-flow: column nowrap;
  gap: em(12);
}

.andes-dropdown {
  margin-top: em(12);
}

input#zip_code.andes-form-control__field {
  width: inherit !important;
}
