@import "shared";

& {

  .cow-navbar-v2+.brand--mobile {
    top: 0;
    left: 0;
  }

  .cow-payment_summary_mobile {

    .collapsible-content__detail {

      >span.andes-typography {
        font-weight: $cow-font-weight-semibold;
      }

      .collapsible-content__detail--items {

        .row-item:not(:first-child) {
          .row-item__label--product {
            span {
              margin-left: 14px;
            }
          }

          .row-item__icon-container {
            align-items: center;

            .andes-tooltip__trigger {
              margin-left: 8px;

              svg {
                path {
                  fill: $andes-accent-color
                }
              }
            }
          }
        }
      }

      .split-payment {

        span.andes-typography {
          font-size: $cow-font-14;
        }

        >span.andes-typography {
          font-weight: $cow-font-weight-semibold;
        }
      }
    }
  }

  .layout__main-content {

    .layout__title {
      text-align: center;
    }

    .content {
      padding-bottom: 158px;

      .paymentMethodInfo {
        .andes-card.andes-card--flat {

          .andes-list__item {
            padding-left: 24px;
            padding-right: 24px;
          }

          .andes-card__footer {
            padding: 16px 24px;
          }
        }
      }

      .tyc {
        text-align: center;
        line-height: 16px;
        margin-top: 24px;
        font-size: $cow-font-14;
      }
    }

    &.cow-brand-content {
      margin-top: 209px;
    }

  }

  .cow-payment_summary {
    .summary-total {
      margin-top: 25px;
      text-align: center;
    }
  }

  .tyc {
    a:visited,
    a:active,
    a:hover,
    a:link {
      text-decoration: none;
      color: $andes-accent-color;
    }
  }

  .footer-terms {
    margin-top: 40px;
  }
}
