.bottomSheetWithBpp {
  height: em(360);

  .andes-form-control {
    margin-bottom: em(8);
  }
}

.bottomSheet {
  height: em(284);

  .andes-form-control {
    margin-bottom: em(16);

    &__validated-icon {
      width: $cow-font-32;
      height: $cow-font-28;
    }
  }
}

.andes-bottom-sheet {
  .icon-wrapper {
    .login-incentive-mp-logo {
      max-width: em(30);
      max-height: em(30);
    }
  }

  // Neutralize the default behavior of the Andes BottomSheet component
  // That distorts the original color of the MP icon
  // webpack://px-checkout-frontend/node_modules/@andes/button/lib/styles/_mixins.scss
  .andes-button--loud path {
    stroke: transparent !important;
  }

}

.mpLogoWrapper {
  margin-right: em(6);
}

.bpp {
  padding: 0 0 em(16) 0;

  &__title {
    font-size: em(16);
  }

  &__shield {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: em(5) 0;
  }

  &__shield span {
    color: $cow-color-success;
    font-weight: 600;
    font-size: $cow-font-12;
    line-height: em(11, 16);
    margin: 0 0 0 em(5);
  }
}

.bottomSheetWithBpp .andes-form-control {
  margin-bottom: 0;
}



