.cow-duplicated-payment-modal {
  &__message {
    display: flex;
    text-align: center;
  }

  .andes-modal__actions {
    gap: 8px;

    .andes-button {
      margin-left: 0 !important;
    }
  }
}

/*
   TODO - TECH DEBT: Check this style because its affecting another components
   We must also consider that after the rollout of the rebranding and the modals,
   the legacy duplicate payment modal should be removed.
*/
.andes-modal__portal {
  z-index: 10000;
  position: relative;
}
