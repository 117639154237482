@import "shared";

.instruction {
  &-action {
    position: absolute;
    bottom: em(16);
    left: em(24);
    width: calc(100% - 3em);
  }
}

.instruction-modal {
  max-width: 100%;
  padding: em(24) em(24);
  margin: em(8);
}
