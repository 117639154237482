@import "shared";

.form-actions {
  flex-direction: column-reverse;

  button {
    width: 100% !important;
  }

  &:last-child {
    button {
      margin-top: $cow-spacing-8;
    }
  }
}
